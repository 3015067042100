// sass-lint:disable no-vendor-prefixes

// Use the saner border-box model for all elements.
* {
  box-sizing: border-box;
}

html {
  @include define-type-sizing();
  // Change the default font family in all browsers (opinionated).
  @include typeface(body);
  // Prevent adjustments of font size after orientation changes in IE and iOS.
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  // On short pages, we want any background gradients to fill the entire height
  // of the browser.
  min-height: 100%;
}

body {
  // Output a horizontal grid to help with debugging typography. The
  // $typey-debug variable will toggle its output.
  @include typey-debug-grid();
  // Remove default margin and padding.
  margin: 0;
  padding: 0;
  color: color(text);
  background-color: color(text-bg);
}

/* Ad container styles */
.simplead-container {
  text-align: center;
  margin-bottom: .7rem;
}

.simpleads-923 {
  margin-bottom: 1.6em;
}

.simpleads-989 {
  margin: 2.3em 0;
}

.middle-ad-block {
  margin-bottom: 3rem;
}

/* Make ad blocks float side-by-side, for now */

.pane-simpleads-ad-groups-61,
.pane-simpleads-ad-groups-57,
.pane-simpleads-ad-groups-43,
.pane-simpleads-ad-groups-41 {
  width: 160px;
  float: left;
  margin-right: 20px;
}

.pane-simpleads-ad-groups-55,
.pane-simpleads-ad-groups-58,
.pane-simpleads-ad-groups-44,
.pane-simpleads-ad-groups-46 {
  width: 160px;
  float: left;
}

/* Easy breadcrumb styles */

.easy-breadcrumb {
  font-size: 80%;
  letter-spacing: .7px;
  text-transform: uppercase;
  color: #8a8a8a;
  margin-bottom: 1em;
}

@media (max-width: 768px) {
  .easy-breadcrumb {
    display: none;
  }
}

// Sponsor stuff

.sponsor-text {
  text-align: center;
  font-size: 70%;
  line-height: 120%;
  color: #8a8a8a;
  z-index: 99;

  p {
    margin-bottom: .75rem;
  }

}

.node-type-landing-page {

  .pane-node-field-sponsor-image {
    width: 17%;
    float: right;
    margin-left: 1.2em;
    margin-top: 0;
    text-align: center;

    img {
      width: 100%;
    }

    h3 {
      margin: 30px 0 0;
    }
  }
}

// Article styles

.field-name-body {

  img {
    max-width: 100%;
    height: auto;
  }

  ul {
    li {
      margin-bottom: 1em;
    }
  }

}

/* Center the main image and give it some bottom margin */

.pane-node-field-story-images {
  text-align: center;
  margin-bottom: 1.4rem;
}

.view-article-author-and-date {
  width: 50%;

  h4 {
    color: #6a6a6a;
    text-transform: uppercase;
  }
}

.node-type-article {

  .pane-node-field-social-sharing,
  .pane-node-easy-social-1 {
    float: right;
  }

}

// Calendar styles

.node-type-event {

  .field-name-field-event-date {
    font-size: 1.2em;
    font-weight: 300;
    margin: 1em 0;
    font-weight: 600;
  }

  .field-name-field-event-categories {
    margin-bottom: 1em;
  }

  .pane-node-field-image {
    float: right;
    margin: 0 0 1em 2em;
  }

  .field-name-field-address {
    margin-bottom: 1em;
    font-size: 1.2em;
    color: #6a6a6a;
  }

  .event-info-wrapper {
    margin: 2em 0;
    overflow: hidden;
    clear: both;
  }

  .field-name-field-geolocation {
    float: right;
    width: 49%;
  }

  .event-info {
    width: 47%;
    float: left;
    margin: 0;
    background: #ffd79e;
    height: 250px;
    padding: 1em 2em;

    .field {
      margin: 1em 0;
    }

    .field-type-link-field {
      margin: 2em 0;

      a,
      a:link,
      a:visited {
        color: #993300;
        background: none;
        border: 3px solid #993300;
        padding: 8px 28px;
        transition: 250ms ease-in;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: .7px;
      }

      a:hover {
        background: #993300;
        color: #fff;
        text-decoration: none;
      }
    }

  }
}

// Calendar view

.view {

  .date-nav-wrapper {
    margin-top: 3em;

    .date-heading {
      text-align: left;

      h3 {
        text-align: center;
      }
    }

  }

  .date-nav-wrapper {

    .date-prev,
    .date-next {
      list-style-type: none;
      padding: 0;
      background: #fff;
      color: #993300;

      a {
        font-weight: 300;
        font-size: 1.5em;
      }
    }

    .date-prev {
      margin-right: 1em;
    }
  }
}

.calendar-today,
.pane-calendar-panels-panel-pane-1,
.pane-calendar-panels-panel-pane-2,
.pane-calendar-panels-panel-pane-3,
.pane-calendar-panels-panel-pane-4,
.ctools-collapsible-container,
.pane-popular-birthdays-panel-pane-1 {
  padding:  .7em 1.5em 0;
  margin-bottom: 1.5em;
  background: #fff;
  border: 1px solid #ccc;
  margin-right: 8px;
  line-height: 1.2em;
  transition: 500ms ease-in;

  .ctools-toggle {
    display: none;
  }

  p {
    font-size: 85%;
    margin: 1rem 0;
  }

  .item-list {

    li {
      margin-bottom: .83rem;
    }

  }

  h3,
  h2.pane__title,
  h2.pane-title {
    font-size: 1.4em;
    line-height: 1.2em;
    font-weight: 300;
    margin-top: .5em;
    margin-bottom: 5px;
    text-align: center;
  }

  .view-content {
    font-size: 90%;

    h3 {
      text-align: left;
      text-transform: inherit;
      font-size: 1.1em;
      color: #2a2a2a;
      margin-top: 1.5rem;
      margin-bottom: .6rem;
      font-weight: normal;
      letter-spacing: 0;
    }
  }

  .calendar-buttons {
    margin: 1em 0 .3em;

    a,
    a:visited {
      display: block;
      width: 70%;
      margin: 1em auto;
      padding: 4px 0;
      color: #993300;
      background: #fff;
      border: 1px solid #993300;
      text-transform: uppercase;
      text-decoration: none;
      font-size: 82%;
      letter-spacing: .7px;
      font-weight: bold;
      text-align: center;
      transition: 250ms all ease-in;
    }

    a:hover {
      text-decoration: none;
      color: #fff;
      background: #993300;
    }

  }

  .pane-calendar-panels-panel-pane-3 {

    .view-content {
      margin-top: 2rem;
    }

  }

  .view-calendar,
  .view-calendar-panels {
    .views-row {
      margin-bottom: 1em;
    }

    .today-date {
      text-align: center;
      margin: 0 0 20px;
    }
  }

  .views-field-field-address-locality,
  .views-field-field-address-name-line {
    font-size: 90%;
    color: #7a7a7a;
  }
}

.ctools-collapsible-container.ctools-collapsed {
  background: #993300;
  transition: 500ms ease-out;

  h2 {
    color: #fff !important;
    font-weight: 500;
    margin: .2rem 0 .6rem;
  }
}


.view-display-id-block_1 {
  width: 100%;
  margin: 0 8px 2em 0;
  border: 1px solid #e5e5e5;

  .date-nav {
    padding-bottom: 0;
  }

  .date-nav-wrapper {
    margin-top: 1em;
    padding-left: 1em;

    h3 {
      font-size: 1.25em;
    }

    .date-next,
    .date-prev {
      font-size: 2em;
    }

    .date-next {
      margin-right: 10px;
    }

    .date-prev {
      left: 0;
      right: inherit;
    }

  }

  .calendar-calendar {

    td {
      text-align: center;
      vertical-align: middle;
      padding: 5px 0;
      border-top: 1px solid #ccc;

      a,
      a:visited {
      text-decoration: none;
      color: #993300;
      }

      a:hover {
        color: #6a6a6a;
      }
    }

    table.mini td.empty {
      border-color: #ccc;
    }

    th.days {
      border: inherit;
      border-top: 1px solid #ccc;
      padding: 5px 0;
      background: #fff;
      color: #444;
    }

    tr {

      td.today {
        background-color: #993300;
        transition: 250ms ease-in;

        a,
        a:visited,
        a:hover {
          color: #fff;
          font-weight: 600;
        }
      }
    }

    td.today:hover {
      background: #6a6a6a;
    }
  }
}

.view-calendar,
.view-promoted-events {

  h4 {
    margin-bottom: .5rem;
    margin-top: 0;
    font-size: 1.2rem;
  }

  .views-field-field-image {
    float: left;
    margin: 0 1.5rem 0 0;
  }

  .views-row {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #b5b5b5;
    overflow: hidden;
  }

  .views-row-first {
    padding-top: 2rem;
    border-top: 1px solid #b5b5b5;
  }
}

.view-promoted-events {

  .views-row {
    border: 1px solid #b5b5b5;
  }

  .views-row {
    margin-bottom: 1rem;
    padding: 1.3rem;
    border-bottom: 1px solid #b5b5b5;
    overflow: hidden;
  }

  .views-field-nothing {
    font-size: 70%;
    text-transform: uppercase;
    letter-spacing: .5px;
    color: #8a8a8a;
    margin-bottom: 3px;
  }

  .views-field-body {
    font-size: 85%;
    line-height: 18px;
    margin-top: 9px;
  }

  .views-field-field-address-name-line,
  .views-field-field-event-date {
    font-weight: 600;
  }

  .views-row-first {
    padding-top: 1.3rem;
    border-top: inherit;
    border: 1px solid #b5b5b5;
    padding-bottom: 0;
  }

}

@media (max-width: 768px) {
  .node-type-event {

    .pane-node-field-image {
      float: none;
      margin: 2em auto;
    }
    .pane-node-field-geolocation {
      float: none;
      width: 100%;
      margin: 1em 0;
    }
    .pane-node-field-website {
      float: none;
      width: 100%;
      margin: 0 0 1em;
    }
  }

  .pane-calendar-panels-panel-pane-1,
  .pane-calendar-panels-panel-pane-2,
  .pane-calendar-panels-panel-pane-3,
  .pane-calendar-panels-panel-pane-4,
  .ctools-collapsible-container {
    padding: 0;
    margin-bottom: 1.5em;
    margin-top: 1.5em;
    border: none;
    margin-right: 0;
  }
}
