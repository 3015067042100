// Dependencies.
@import 'base/headings/headings';
@import 'components/clearfix/clearfix';

// Branding header
//
// Markup: header.twig
//
// Style guide: components.header

header {
  box-shadow: 0px 1px 3px #9a9a9a;
}
.header,
%header {
  @extend %clearfix;
  width: 100% !important;
  height: 200px;
  margin: 0;
  padding: 0;
  background: url("../images/headerBG.png") no-repeat top center;
  transition: height .25s;

  // Wrapping link for logo.
  &__logo {
    float: left;
    margin: 0 10px 0 0;
    padding: 22px 0 0 0;

    @include rtl() {
      float: right;
      margin: 0 0 0 10px;
    }
  }

  .header-inner {
    max-width: 1250px;
    padding: 0 20px;
    margin: 0 auto;
    height: 100%;
    position: relative;

    .block-custom-search-blocks {
      position: absolute;
      right: 20px;
      top:30%;

      .form-type-textfield,
      .form-actions {
        display: inline-block;
      }

      input.form-text {
        width: 250px;
        padding: 3px 10px;
        border: none;
      }

      input.form-submit {
        background: none;
        border:3px solid #663300;
        color: #663300;
        font-size: 90%;
        text-transform: uppercase;
        letter-spacing: .75px;
        padding: 0 6px;
        margin-left: 3px;
        line-height: 22px;
        font-weight: bold;
      }
    }
  }

  // Logo image.
  &__logo-image {
    vertical-align: bottom;
  }

  // Wrapper for website name and slogan.
  &__name-and-slogan {
    float: left;
  }

  // The name of the website.
  &__site-name {
    @extend %h1;
    margin: 0;
  }

  // The link around the name of the website.
  &__site-link {
    &:link,
    &:visited {
      color: color(text);
      text-decoration: none;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  // The slogan (or tagline) of a website.
  &__site-slogan {
    margin: 0;
  }

  #block-block-3 {
    display: none;
    transition: 200ms;
  }

  #block-block-5 {
    margin: 0;
    padding: 0;
    position: absolute;
    left: 305px;
    bottom: 47px;
  }

  #block-block-4 {
    position: absolute;
    right:20px;
    bottom: 4px;

    a,
    a:visited {
      color: #663300;
      transition: 100ms ease-in;
    }

    a:hover {
      color: #6a6a6a;
    }

    i {
      margin-left: 10px;
    }
  }

  // Main menu in header

  .block-menu,
  .block-responsive-menu,
  .block-superfish {
      position: absolute;
      bottom: 11px;
      transition: 200ms ease-in;

      ul.menu,
      ul.horizontal-menu {
        padding: 0;

        li.menu__item,
        li {
          list-style: none;
          float: left;
          margin-right: 40px;

          ul {
            background: #fff;
            border-radius: 0;
            margin-top: -15px;
            padding: .7em 0;
            box-shadow: 0 5px 4px rgba(0,0,0,0.6);

            li {
              margin: .5em 1em;
            }
          }
        }

        li.last {
          margin-right: 0;
        }

        a,
        a.dropdown-toggle,
        a:visited,
        .nolink {
          padding: 0;
          color: #663300;
          text-transform: uppercase;
          letter-spacing: .3px;
          font-size: .93em;
          text-decoration: none;
          transition: 150ms ease-in;
        }

        .nolink {
          cursor: pointer;
        }

        a:hover,
        .nolink:hover {
          text-decoration: none;
          color: #6a6a6a;
        }

      }
    }

  // The secondary menu (login, etc.)
  &__secondary-menu {
    position: absolute;
    top: 0px;
    right: 20px;
    z-index: 999;

    @include rtl() {
      float: left;
    }

    ul.links {
      margin: 10px 0 0 0;

      li {
        padding: 0 0 0 1em;
      }

      a,
      a:visited {
        color: #6a6a6a;
        font-size: .75em;
        text-transform: uppercase;
        letter-spacing: .45px;
        text-decoration: none;
        transition: 150ms ease-in;
      }

      a:hover {
        color: #2a2a2a;
        text-decoration: none;
      }
    }
  }

  // Wrapper for any blocks placed in the header region.
  &__region {
    // Clear the logo.
    clear: both;
  }
}

// Floating header overrides

.header.stickynav-active,
.header.floating-block-active {
  z-index: 999;
  height: 50px;
  background-image: none;
  background-color: #fff;
  box-shadow: 0 1px 6px #666;

  .header__logo,
  .header__secondary-menu {
    display: none;
  }

  .block-superfish {
    top: 18px;
    right: 0;
  }

  #block-block-3 {
    display: inline-block;
    margin-top: 12px;

    img {
      width: 80%;
    }
  }

  .block-custom-search-blocks,
  #block-block-4 {
    display: none;
  }

}

// Menu minipanels
.sf-menu {

  .menu-minipanel-panel {

    .panel-display {
      background: #fff;
    }

  }

}

// Put the footer here for want of a better place

footer {
  width: 100%;
  background: #ffd79e;
  color: #4a4a4a;
  margin-top: 2em;

  a,
  a:link {
    color: #fff;
  }

  .footerInner {
    max-width: 1250px;
    padding: 20px;
    margin: 0 auto;
    text-align: center;
    font-size: .9em;

    .social {
      margin: 2em 0;

      a,
      a:visited {
        color: #663300;
        text-decoration: none;
        transition: 150ms ease-in;
        margin: 5px;
      }

      a:hover {
        text-decoration: none;
        color: #fff;
      }
    }

    .block-mailchimp-signup {

      .form-type-textfield {

        label {
          display: none;
        }

      }

    }

  }
}

.block .header,
.pane-block .header {
  height: inherit;
  width: auto;
  background: none;
}

@media (max-width: 1024px) {
  .header {

    .block-superfish {
      bottom: 14px;

      ul.menu {

        li.menu__item,
        li {
          list-style: none;
          float: left;
          margin-right: 20px;
          font-size: 94%;
        }
    }
  }

  .flexslider {

    .slide-content {
      padding: 0 60px;
    }
  }

}

@media (max-width: 768px) {

  &__secondary-menu {
    display: none;
  }

  .header,
  %header {
    width: 100%;
    height: 145px;

    .header__secondary-menu {
      display: none;
    }

    // Wrapping link for logo.
    &__logo {
      float: none;
      margin: 0 auto;
      padding: 10px 0 0 0;
    }

    .header-inner {
      max-width: none;
    }

    img.header__logo-image {
        height: auto;
        width: 100%;
        margin-top: 10px;
    }

    .block-custom-search-blocks {
      display: none;
    }

    #block-block-4 {
      display: none;
    }

    .block-superfish {
      position: inherit;
      float: left;
      margin-top: 12px;

      .sf-accordion-toggle {
        font-size: 2.3em;
        position: absolute;

        a,
        a:hover,
        a:visited {
          text-decoration: none;
        }
      }

      ul.menu {
        margin: 45px 0;
        background: #fff;

        li {
          padding: 10px 0;
        }
      }
    }
  }

  .header.floating-block-active {

    #block-block-3 {
      display: block;
      position: absolute;
      left: 64px;
    }

    .block-superfish {
      margin-top: 8px;

      ul.menu {
        margin: 40px 0;
        background: #fff;
        padding: 10px 0 20px 10px;

        li {
          padding: 10px 0;
        }
      }
    }

  }

  header {
    box-shadow: inherit;
  }
}
}
