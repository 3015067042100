// Box
//
// A simple box style.
//
// A Drupal block is often styled as a box. However, this design component is
// not applied to any blocks by default; it is used as an example of how to
// build and document a CSS component.
//
// "box" is the name of our component, so we define a `.box` class that we can
// apply to any HTML element that needs the box styling. We also provide a
// `%box` placeholder selector so that we can easily extend the basic box
// component with `@extend %box;`.
//
// Take a look at the source code for this component for more information about
// building a good component.
//
// :hover - The hover/focus styling.
// .box--highlight - The "highlight" box variant.
//
// Markup: box.twig
//
// Style guide: components.box

.box,
%box {
  @include margin-bottom(1);
  border: 5px solid color(border);
  padding: 1em;

  // Sass compiles this to the selector: .box__title, %box__title {}
  // The "__" (double underscore) is part of a BEM naming convention that
  // indicates the "title" is an _element_ of (a piece of) the "box" component.
  &__title {
    margin: 0;
  }

  .layout-3col__full {
    padding: 0;
  }

  // Sass compiles this nested ruleset's selector into:
  // .box:focus, .box:hover, .box.is-focus, .box--is-focus,
  // %box:focus, %box:hover, %box.is-focus, %box--is-focus {}
  //
  // The ".box--is-focus" class is a stray class we don't actually want, but we
  // deal with it because we often want a placeholder selector like
  // %box--is-focus in case we need to use @extends later.
  //
  // The ".is-" part of the class name is a Drupal 8 BEM naming convention that
  // indicates the "focus" is a _state_ of the "box" component.
  &:focus,
  &:hover,
  &.is-focus,
  &--is-focus {
    border-color: color(text);
  }

  // Sass compiles this to the selector: .box--highlight, %box--highlight {}
  // The "--" (double dash) is part of a BEM naming convention that indicates
  // the "highlight" is a _modifier_, creating a new variation of the standard
  // "box" component.
  &--highlight {
    border-color: color(link);
  }
}

//
// Drupal selectors.
//
// In this part of the file, we write some fugly selectors if we can't figure
// out how to insert the class we want (.box) into the Drupal theme system. Then
// we @extend from the fugly selector into the nicely-written component
// selector.
//

// You could create a theme hook suggestion and override the block.tpl.php just
// for the sidebar regions or write a HOOK_preprocess_block() function to check
// for the regions and insert the classes into the right variables, or, if you
// have no idea what I'm talking about, you could just use this fugly CSS
// selector:
//
// [class$="sidebar"] {
//   .block {
//     @extend %box;
//   }
//
//   .block__title {
//     @extend %box__title;
//   }
// }

.view-front-page-articles-main {

  .views-row {
    margin-bottom: 3.2em;
    overflow: hidden;
    text-align: left;
  }

  h2 {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .views-field-field-story-images {
    float: left;
    margin-right: 2em;
  }

  .views-field-created {

    h4 {
      font-size: 1.1rem;
      color: #666;
      font-family: 'Lato', Helvetica, Arial, sans-serif;
      text-transform: uppercase;
      letter-spacing: .25px;
    }
  }

}

.pane-front-page-articles-secondary-panel-pane-9,
.pane-front-page-articles-secondary-panel-pane-2,
.pane-front-page-articles-main-panel-pane-2,
.articles-list,
.pane-front-page-articles-main-panel-pane-1,
.pane-front-page-articles-secondary-panel-pane-8,
.pane-front-page-articles-secondary-panel-pane-4 {
  overflow: hidden;
  text-align: center;

  h2.pane__title {
    font-weight: 300;
    display: inline-block;
    position: relative;
    color: #2a2a2a;
    margin: 0 auto 1em;
    background: #fff;
    padding: 0 .6em;
  }

  h2.pane__title::before,
  h2.pane__title::after {
    background: #9a9a9a;
    content: " ";
    display: block;
    height: 2px;
    position: absolute;
    top: 50%;
    width: 40%;
  }

  h2.pane__title::before {
    right: 100%;
  }

  h2.pane__title::after {
    left: 100%;
  }

}

.view-school-guides {

  .view-header {
    text-align: center;

    h2 {
      font-weight: 300;
      display: inline-block;
      position: relative;
      color: #2a2a2a;
      margin: 1em auto 0;
      padding: 0 .6em;
    }

    h2::before,
    h2::after {
      background: #9a9a9a;
      content: " ";
      display: block;
      height: 2px;
      position: absolute;
      top: 50%;
      width: 40%;
    }

    h2::before {
      right: 100%;
    }

    h2::after {
      left: 100%;
    }

  }

}

.comment__section {

  h2.comment__section-title,
  h2.comment__form-title {
    font-weight: 300;
    display: inline-block;
    position: relative;
    color: #2a2a2a;
    margin: 0 auto 1em;
    background: #fff;
    padding: 0 .6em;
  }

  h2.comment__section-title::before,
  h2.comment__form-title::before,
  h2.comment__section-title::after,
  h2.comment__form-title::after {
    background: #9a9a9a;
    content: " ";
    display: block;
    height: 2px;
    position: absolute;
    top: 50%;
    width: 40%;
  }

  h2.comment__section-title::before,
  h2.comment__form-title::before {
    right: 100%;
  }

  h2.comment__section-title::after,
  h2.comment__form-title::after {
    left: 100%;
  }

}

.view-front-page-articles-secondary,
.view-taxonomy-term {
  margin-bottom: 3em;

  .view-content {
    overflow: hidden;
  }

  .views-row {
    width: 45%;
    float: left;
    margin-bottom: 2.25em;
    text-align: left;
  }

  .views-row-odd {
    margin-right: 4em;
  }

  .more-link {
    display: block;
    margin: 0 auto;
    width: 220px;
    text-align: center;

    a,
    a:link,
    a:visited {
      color: #993300;
      text-transform: uppercase;
      font-size: 1.1em;
      letter-spacing: .7px;
      border: 3px solid #993300;
      font-weight: bold;
      padding: 4px 10px;
    }

    a:hover {
      text-decoration: none;
      color: #fff;
      background-color: #993300;
    }
  }

  h2 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .views-field-created {
    color: #8a8a8a;
    text-transform: uppercase;
    letter-spacing: .25px;
  }

}

.pane-front-page-articles-secondary-panel-pane-9 {

  .view-front-page-articles-secondary {
    margin-bottom: 0;
  }

}

.more-button {

  .more-link {
    display: block;
    margin: 0 auto 4em;
    width: 300px;
    text-align: center;

    a,
    a:link,
    a:visited {
      color: #993300;
      text-transform: uppercase;
      font-size: 1.1em;
      letter-spacing: .7px;
      border: 3px solid #993300;
      font-weight: bold;
      padding: 4px 10px;
    }

    a:hover {
      text-decoration: none;
      color: #fff;
      background-color: #993300;
    }
  }
}

.view-school-guides,
.panel-pane .view-id-summer_camp_views {
  padding: 1em 2em 2em;
  background: #ffdeae;
  margin-bottom: 2em;

  label {
    font-size: 1.25rem;
  }

  .form-type-bef-link {
    display: inline-block;
    width: 130px;
    margin: 1em 1em 0 0;
  }

  .views-row {
    width: 100%;
    padding: 2em;
    margin-bottom: 1.5em;
    background: #fff;
  }

  .Promoted {
    border: 3px solid #993300;
  }

  .views-field-sticky {
    font-size: .75rem;
    color: #8a8a8a;
  }

  .view-content {
    overflow: hidden;
    margin: 1em 0 0;
  }

  h3 {
    line-height: 1.7em;
    margin-top: 0;
    margin-bottom: .4rem;
    text-transform: inherit;
    font-weight: 600;
  }

}

.section-marin-teen-volunteer-opportunities {

  .views-row {
    margin-bottom: 2em;
    padding-bottom: 2em;
    border-bottom: 1px solid #ddd;
  }

  h2 {
    font-size: 1.45rem;
    margin-bottom: .2rem;
  }

  .views-field-field-volunteer-address-locality {
    margin-bottom: 1rem;
  }
}

.section-birthdays {

  .view-school-guides {

    .form-type-bef-link {
      display: inline-block;
      width: 230px;
      margin: 1em 1em 0 0;
    }
  }

}

.section-classes-and-camps {

  .view-school-guides {

    .form-type-bef-link {
      width: 170px;

      a.active {
        color: #fff;
        background: #993300;
        padding: 3px 10px;
      }
    }
  }
}

.panel-pane .view-id-summer_camp_views {
  margin-top: 0;
}

.view-summer-camp-views {
  margin-bottom: 2em;

  label {
    font-size: 1.25rem;
  }

  .form-type-bef-link {
    display: inline-block;
    width: 130px;
    margin: 1em 1em 0 0;
  }

  .views-row {
    width: 100%;
    margin-bottom: 2.5em;
    overflow: hidden;

    .views-field-field-image {
      float: right;
    }
  }

  .view-content {
    overflow: hidden;
    margin: 2em 0 0;
  }

  h3 {
    line-height: 1.7em;
    margin-top: 0;
    margin-bottom: .4rem;
    text-transform: inherit;
    font-weight: 600;
  }


}

.views-widget select.form-select,
select.ctools-jump-menu-select {
  background: #fff;
  padding: .5em;
  margin: 1em 0;
  border: 2px solid #dc5f13;
}



.flexslider {
  background: #3a3a3a;
  border: 0;
  border-radius: 0;
  margin-bottom: 0;

  .flex-direction-nav {

    a {
      line-height: 40px;
    }

    a:before {
      color: #fff;
    }
  }

  .views-field-field-slide-image {
    position: relative;
  }

  .views-field-field-slide-image::before {
    content:"";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    //background: rgba(0,0,0,0.3);
    background: linear-gradient(to right, rgba(0,0,0,0.36), rgba(0,0,0,0.0));
  }

  .slide-content {
    width: 100%;
    max-width: 1250px;
    padding: 0 20px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    z-index: 99;

    h2.slide-text {
      font-size: 2.7rem;
      line-height: 3rem;
      font-weight: 300;
      text-transform: uppercase;
      width: 40%;
      color: #fff;
      letter-spacing: .5px;
    }

    .slide-link {
      margin-top: 10px;

      a,
      a:link,
      a:visited {
        font-size: 1.1em;
        padding: 5px 15px;
        border: 3px solid #fff;
        color: #fff;
        letter-spacing: 1.2px;
        transition: 100ms ease-in;
        text-decoration: none;
      }
      a:hover {
        background: rgba(0,0,0,0.6);
        text-transform: none !important;
        border-color: #3a3a3a;
      }
    }

    h3 {
      color: #fff;
      width: 40%;
      text-transform: none;
      line-height: normal;
    }
  }
}

.node-type-landing-page {

  .field-name-body {
    margin-bottom: 3.2rem;
  }
}

/* Real estate listing styles */
.listing--address {
  font-size: 1.2rem;

  .street-block {
    display: inline-block;
    margin-right: .25rem;
  }

  .locality-block {
    display: inline;
  }
}

.listing--price {
  font-size: 1.2rem;
  font-weight: bold;
}

.listing--info {
  float: left;
  margin: 1.5rem 2rem 0 0;
  padding: 2rem;
  width: 40%;
  background: #eaeaea;
}

.listing--map {
  float: left;
  width: 55%;
  margin-top: 1.5rem;
}

// Summer camp nodes and stuff

.section-summercamps {
  .field-name-body {
    margin-bottom: 1em;
  }
}

.summercamp-sponsor {
  width: 200px;
  float: right;
  margin: 0 0 1em 2em;
  text-align: center;

  img {
    width: 100%;
    height: auto;
  }

  .pane__title {
    font-weight: 600;
    font-size: 110%;
    margin-bottom: 7px;
  }
}

.birthday-sponsor {
  width: 250px;
  float: right;
  margin: 0 0 1em 1.3em;
  text-align: center;

  img {
    width: 100%;
    height: auto;
  }

  .pane__title {
    font-weight: 600;
    font-size: 110%;
  }
}

.node-type-birthday-listing {

  .field-name-field-image {
    margin-bottom: 1em;
  }

  .pane-node-field-phone,
  .pane-node-field-website,
  .pane-node-field-email,
  .pane-node-field-address {
    margin-top: 1em;
  }
}


.pane-promoted-listings-panel-pane-1,
.pane-promoted-listings-panel-pane-2,
.pane-promoted-listings-panel-pane-3 {
  background: #ffdeae;
  padding-top: .6em;

  h2.pane__title {
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 300;
    font-size: 1.6em;
  }
}

.pane-promoted-listings-panel-pane-3 {
  margin-bottom: 3em;
}

.view-promoted-listings {
  background: #ffdeae;
  padding: .7em 0 2em;

  .flexslider {
    background: #ffdeae;

    .slides > li {
      text-align: center;
      margin-right: 20px;
      min-height: 228px;
      background: #fff;
      padding: 10px;

      h4 {
        margin-top: .5em;
        margin-bottom: 0;
        line-height: 1.3rem;
      }
    }
  }

  .flex-viewport {
    margin: 0 2.7em;
  }

}

.camp-selectors {
  overflow:hidden;
  width: 100%;
  background: #ffdeae;
  margin-bottom: 2em;
  text-align: center;

  .view-summer-camp-views {
    width: 33%;
    float: left;

    .form-item {
      margin: 0 1.5em;

      .ctools-jump-menu-select {
        width: 100%;
      }
    }

  }
  .form-type-textfield {
    label {
      font-size: 1.25rem;
      margin-bottom: 10px;
    }
    input.custom-search-box {
      border: 2px solid #dc5f13;
      padding: 5px;
    }
  }
}

.node-type-summer-camp-listing,
.node-type-school-listing {

  .pane-node-field-geolocation {
    width: 49%;
    float: left;
    margin: 2em 0;
  }

  .pane-node-field-image {
    float: right;
    margin: 0 0 1em 2em;
  }

  .field-name-field-summer-camp-activities,
  .field-name-field-summer-camp-age-level {
    float: left;
    width: 49%;
    margin-top: 1.5em;
  }

  .field-name-field-school-address {
    display: inline-block;
  }

  .pane-node-field-summer-camp-location {
    width: 47%;
    float: left;
    margin: 2em 1em 2em 0;
    background: #ffd79e;
    min-height: 250px;
    clear: both;

    .field-name-field-summer-camp-location {
      margin: 1.5rem;

      .field-item {
        margin-bottom: .7em;
      }
    }

  }

  .pane-node-field-website {
    margin: 1em 0 2em;

    a,
    a:visited {
      color: #fff;
      background: #993300;
      padding: 6px 18px;
      transition: 250ms ease-in;
    }

    a:hover {
      background: #4a4a4a;
      text-decoration: none;
    }

  }

}

/* Summer camp webform */

.webform-client-form {

  label {
    margin-bottom: 7px;
  }

  fieldset {
    padding: 0;
    margin: 1.5em 0;
    border: 0;

    legend {
      font-weight: 600;
      margin-left: 0;
      padding: 0;
    }

    .fieldset-description {
      font-size: .875rem;
      line-height: 1.5em;
      color: #7a7a7a;
    }

    .fieldset-wrapper {
      border-top: 1px solid #bababa;
      padding-top: 15px;
      margin-top: 15px;
    }
  }
  .form-item {
    .description {
      line-height: 1.5em;
      margin-bottom: 10px;
      color: #7a7a7a;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {

  .view-front-page-articles-main {

    .views-row {
      margin-bottom: 2.5em;
      overflow: hidden;
    }

    h2 {
      font-size: 1.4rem;
      line-height: 1.7rem;
      margin: .5rem 0;
    }

    .views-field-field-story-images {
      width: 295px;
      margin-right: 1.5em;
    }

    .views-field-created {

      h4 {
        font-size: .8rem;
        margin: 5px 0;
      }
    }

  }

  .flexslider {

    .slide-content {
      padding: 0 60px;
    }
  }

  .view-front-page-articles-secondary {

    .views-row {
      //width: 288px;
    }

    .views-row-odd {
      margin-right: 2em;
    }

    h2 {
      font-size: 1rem;
      line-height: 1rem;
      margin-top: .25rem;
      margin-bottom: .25rem;
    }

  }
}

@media (max-width: 768px) {

  .view-front-page-articles-main {

    .views-row {
      margin-bottom: 3.5em;
      overflow: hidden;
    }

    h2 {
      font-size: 1.4rem;
      line-height: 1.7rem;
      margin: .5rem 0;
    }

    .views-field-field-story-images {
      float: none;
      margin-right: 0;
    }

    .views-field-created {

      h4 {
        font-size: .8rem;
        margin: 5px 0;
      }
    }

  }

  .view-front-page-articles-secondary {

    .views-row {
      width: 100%;
      float: none;
      margin-bottom: 2em;
    }

    .views-row-odd {
      margin-right: 0;
    }

    h2 {
      font-size: 1rem;
      line-height: 1rem;
      margin-top: .25rem;
      margin-bottom: .25rem;
    }

  }

  .listing--info {
    float: none;
    width: 100%;
    margin: 1.5em 0;
  }

  .listing--map {
    float: none;
    margin: 0 0 1.5em;
    width: 100%;
  }

  .node-type-summer-camp-listing {

    .pane-node-field-geolocation {
      width: 100%;
      float: none;
      margin: 1em 0;
    }

    .field-name-field-summer-camp-activities,
    .field-name-field-summer-camp-age-level {
      float: none;
      width: 100%;
      margin-top: 1.5em;
    }

    .pane-node-field-summer-camp-location {
      width: 100%;
      float: none;
      margin-top: 1em;
      height: auto;

      .field-name-field-summer-camp-location {
        margin: 0;
        padding: 1.5rem;
      }
    }
  }

  .view-summer-camp-views {
    label {
      font-size: 1rem;
    }
  }

  .camp-selectors {
    overflow:hidden;
    width: 100%;
    background: #ffdeae;
    margin-bottom: 2em;
    text-align: center;

    .view-summer-camp-views {
      width: 100%;
      float: none;
      margin-bottom: -20px;

      label {
        font-size: 1em;
        margin-bottom: 5px;
      }

      .form-item {
        margin: 0 1.5em;

        .ctools-jump-menu-select {
          width: 100%;
          margin: 5px 0;
        }
      }

    }
    .form-type-textfield {

      label {
        font-size: 1rem;
        margin-bottom: 5px;
      }
    }
    .search-form {
      margin-top: 2em;

      .form-item {
        margin: 0 1.5em;
      }

      .form-text {
        margin-bottom: 5px;
      }
    }
  }

  .flexslider {

    .slide-content {
      padding: 0 0 0 48px;

      h2.slide-text {
        font-size: 2rem;
        line-height: 2.2rem;
        font-weight: 300;
        text-transform: uppercase;
        width: 70%;
        color: #fff;
        letter-spacing: .5px;
      }

      .slide-link {
        margin-top: 20px;
      }

      h3 {
        color: #fff;
        width: 70%;
        text-transform: none;
        line-height: normal;
      }
    }
  }

}

// Instagram block

.block-instagram-block {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 30%;
  background: #fff;
  padding: 1em 2em 2em;
  box-shadow: 0 0 8px #999;
}

/** Promoted events **/
/*
.view-promoted-events {
  margin-bottom: 2rem;
  overflow: hidden;

 .views-row {
    width: 30%;
    float: left;
    margin-right: 1.85rem;
    position: relative;
  }

  .views-field-sticky {
    position: absolute;
    left: 0;
    top: 0;
    padding: .1em .7em;
    color: #fff;
    background: #dc5f13;
    font-size: .7em;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .views-row-last {
    margin-right: 0;
  }

  .views-field-field-event-date,
  .views-field-field-address-name-line {
    font-size: .85em;
  }

  h3 {
    font-weight: 600;
    font-size: 1em;
    line-height: 1.2em;
    text-transform: inherit;
    margin: .3rem 0;
    letter-spacing: normal;
  }
}

@media (max-width: 420px) {
  .view-promoted-events {
    .views-row {
      width: 100%;
      float: none;
      margin-right: 0;
      margin-bottom: 1em;
    }
  }
}*/

/* Event block for home page */

.event-block-widget {
  margin-bottom: 1.5em;
  position: relative;
  z-index: 2;

  a {
    text-decoration: none;
    color: #4a4a4a;

    &:hover {
      color: #993300;
    }
  }
  h3 {
    margin-top:0;
    margin-bottom: 0;
    font-size: 1.5rem;
    text-transform: none;
    font-weight: 600;

    a {
      color: #993300;

      &:hover {
        color: #4a4a4a;
      }
    }
  }
  .event-text {
    width: 70%;
  }
  .event-block__blurb {
    font-size: .9rem;
  }
  .event-block__calendar {
    margin-top: 7px;
    margin-right: 8px;
    float: right;
    width: 25%;
    text-align: center;
    border-radius: 7px;
    box-shadow: 0 1px 5px #999;
    text-transform: uppercase;
    font-weight: 600;
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: -8px;
      z-index: 2;
      width: 7px;
      height: 20px;
      background-color: #aaa;
      border-radius: 3px;
      box-shadow: 0 2px 2px #222;
    }

    &::before {
      left: 12px;
    }

    &::after {
      right: 12px;
    }

    .month {
      background-color: #993300;
      color: #fff;
      padding: 5px 0;
      border-radius: 7px 7px 0 0;
    }

    .day {
      padding: 10px 0;
      font-size: 2rem;
    }
  }
}

/* MailChimp form block styles */

.pane-mailchimp-signup-marin-mommies-list,
.mailchimp-signup {
  background-color: #d1e1ff;
  padding: 1em;
  margin-bottom: 1em;
  margin-right: 8px;

  h2 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1em;
    line-height: normal;
    font-family: lato;
    font-weight: 600;
    color: #3a3a3a;
  }

  label {
    display: none;
  }

  .mailchimp-newsletter-mergefields,
  .mc-field-group,
  .form-actions {
    display: inline-block;
  }

  #mc_embed_signup {

    input {
      border: none;
    }
    form {
      padding: 10px 0 0;
    }
    .mc-field-group {
      width: 75% !important;
    }
  }

  .form-submit {
    color: #fff;
    background-color: #993300;
    font-weight: 600;
    border: none;
    border-radius: 7px;
    transition: 250ms;

    &:hover {
      background-color: #663300;
    }
  }

  .form-item {
    margin: 1rem 0 .5rem;
  }
}
