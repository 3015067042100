h1,
h2 {
  font-family: 'Roboto Slab', sans-serif;
}

h3 {
  font-family: 'Lato', sans-serif;
  font-weight: 800;
}

html {
  font-family: 'Lato', sans-serif;
}

body {
  font-family: 'Lato', sans-serif;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 100%;
  line-height: 1.5em;
  font-family: Verdana, Tahoma, "DejaVu Sans", sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  min-height: 100%;
}

@media print {
  html {
    font-size: 12pt;
  }
}

body {
  margin: 0;
  padding: 0;
  color: #2a2a2a;
  background-color: #fff;
}

/* Ad container styles */
.simplead-container {
  text-align: center;
  margin-bottom: .7rem;
}

.simpleads-923 {
  margin-bottom: 1.6em;
}

.simpleads-989 {
  margin: 2.3em 0;
}

.middle-ad-block {
  margin-bottom: 3rem;
}

/* Make ad blocks float side-by-side, for now */
.pane-simpleads-ad-groups-61,
.pane-simpleads-ad-groups-57,
.pane-simpleads-ad-groups-43,
.pane-simpleads-ad-groups-41 {
  width: 160px;
  float: left;
  margin-right: 20px;
}

.pane-simpleads-ad-groups-55,
.pane-simpleads-ad-groups-58,
.pane-simpleads-ad-groups-44,
.pane-simpleads-ad-groups-46 {
  width: 160px;
  float: left;
}

/* Easy breadcrumb styles */
.easy-breadcrumb {
  font-size: 80%;
  letter-spacing: .7px;
  text-transform: uppercase;
  color: #8a8a8a;
  margin-bottom: 1em;
}

@media (max-width: 768px) {
  .easy-breadcrumb {
    display: none;
  }
}

.sponsor-text {
  text-align: center;
  font-size: 70%;
  line-height: 120%;
  color: #8a8a8a;
  z-index: 99;
}

.sponsor-text p {
  margin-bottom: .75rem;
}

.node-type-landing-page .pane-node-field-sponsor-image {
  width: 17%;
  float: right;
  margin-left: 1.2em;
  margin-top: 0;
  text-align: center;
}

.node-type-landing-page .pane-node-field-sponsor-image img {
  width: 100%;
}

.node-type-landing-page .pane-node-field-sponsor-image h3 {
  margin: 30px 0 0;
}

.field-name-body img {
  max-width: 100%;
  height: auto;
}

.field-name-body ul li {
  margin-bottom: 1em;
}

/* Center the main image and give it some bottom margin */
.pane-node-field-story-images {
  text-align: center;
  margin-bottom: 1.4rem;
}

.view-article-author-and-date {
  width: 50%;
}

.view-article-author-and-date h4 {
  color: #6a6a6a;
  text-transform: uppercase;
}

.node-type-article .pane-node-field-social-sharing,
.node-type-article .pane-node-easy-social-1 {
  float: right;
}

.node-type-event .field-name-field-event-date {
  font-size: 1.2em;
  font-weight: 300;
  margin: 1em 0;
  font-weight: 600;
}

.node-type-event .field-name-field-event-categories {
  margin-bottom: 1em;
}

.node-type-event .pane-node-field-image {
  float: right;
  margin: 0 0 1em 2em;
}

.node-type-event .field-name-field-address {
  margin-bottom: 1em;
  font-size: 1.2em;
  color: #6a6a6a;
}

.node-type-event .event-info-wrapper {
  margin: 2em 0;
  overflow: hidden;
  clear: both;
}

.node-type-event .field-name-field-geolocation {
  float: right;
  width: 49%;
}

.node-type-event .event-info {
  width: 47%;
  float: left;
  margin: 0;
  background: #ffd79e;
  height: 250px;
  padding: 1em 2em;
}

.node-type-event .event-info .field {
  margin: 1em 0;
}

.node-type-event .event-info .field-type-link-field {
  margin: 2em 0;
}

.node-type-event .event-info .field-type-link-field a,
.node-type-event .event-info .field-type-link-field a:link,
.node-type-event .event-info .field-type-link-field a:visited {
  color: #993300;
  background: none;
  border: 3px solid #993300;
  padding: 8px 28px;
  transition: 250ms ease-in;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: .7px;
}

.node-type-event .event-info .field-type-link-field a:hover {
  background: #993300;
  color: #fff;
  text-decoration: none;
}

.view .date-nav-wrapper {
  margin-top: 3em;
}

.view .date-nav-wrapper .date-heading {
  text-align: left;
}

.view .date-nav-wrapper .date-heading h3 {
  text-align: center;
}

.view .date-nav-wrapper .date-prev,
.view .date-nav-wrapper .date-next {
  list-style-type: none;
  padding: 0;
  background: #fff;
  color: #993300;
}

.view .date-nav-wrapper .date-prev a,
.view .date-nav-wrapper .date-next a {
  font-weight: 300;
  font-size: 1.5em;
}

.view .date-nav-wrapper .date-prev {
  margin-right: 1em;
}

.calendar-today,
.pane-calendar-panels-panel-pane-1,
.pane-calendar-panels-panel-pane-2,
.pane-calendar-panels-panel-pane-3,
.pane-calendar-panels-panel-pane-4,
.ctools-collapsible-container,
.pane-popular-birthdays-panel-pane-1 {
  padding: .7em 1.5em 0;
  margin-bottom: 1.5em;
  background: #fff;
  border: 1px solid #ccc;
  margin-right: 8px;
  line-height: 1.2em;
  transition: 500ms ease-in;
}

.calendar-today .ctools-toggle,
.pane-calendar-panels-panel-pane-1 .ctools-toggle,
.pane-calendar-panels-panel-pane-2 .ctools-toggle,
.pane-calendar-panels-panel-pane-3 .ctools-toggle,
.pane-calendar-panels-panel-pane-4 .ctools-toggle,
.ctools-collapsible-container .ctools-toggle,
.pane-popular-birthdays-panel-pane-1 .ctools-toggle {
  display: none;
}

.calendar-today p,
.pane-calendar-panels-panel-pane-1 p,
.pane-calendar-panels-panel-pane-2 p,
.pane-calendar-panels-panel-pane-3 p,
.pane-calendar-panels-panel-pane-4 p,
.ctools-collapsible-container p,
.pane-popular-birthdays-panel-pane-1 p {
  font-size: 85%;
  margin: 1rem 0;
}

.calendar-today .item-list li,
.pane-calendar-panels-panel-pane-1 .item-list li,
.pane-calendar-panels-panel-pane-2 .item-list li,
.pane-calendar-panels-panel-pane-3 .item-list li,
.pane-calendar-panels-panel-pane-4 .item-list li,
.ctools-collapsible-container .item-list li,
.pane-popular-birthdays-panel-pane-1 .item-list li {
  margin-bottom: .83rem;
}

.calendar-today h3,
.calendar-today h2.pane__title,
.calendar-today h2.pane-title,
.pane-calendar-panels-panel-pane-1 h3,
.pane-calendar-panels-panel-pane-1 h2.pane__title,
.pane-calendar-panels-panel-pane-1 h2.pane-title,
.pane-calendar-panels-panel-pane-2 h3,
.pane-calendar-panels-panel-pane-2 h2.pane__title,
.pane-calendar-panels-panel-pane-2 h2.pane-title,
.pane-calendar-panels-panel-pane-3 h3,
.pane-calendar-panels-panel-pane-3 h2.pane__title,
.pane-calendar-panels-panel-pane-3 h2.pane-title,
.pane-calendar-panels-panel-pane-4 h3,
.pane-calendar-panels-panel-pane-4 h2.pane__title,
.pane-calendar-panels-panel-pane-4 h2.pane-title,
.ctools-collapsible-container h3,
.ctools-collapsible-container h2.pane__title,
.ctools-collapsible-container h2.pane-title,
.pane-popular-birthdays-panel-pane-1 h3,
.pane-popular-birthdays-panel-pane-1 h2.pane__title,
.pane-popular-birthdays-panel-pane-1 h2.pane-title {
  font-size: 1.4em;
  line-height: 1.2em;
  font-weight: 300;
  margin-top: .5em;
  margin-bottom: 5px;
  text-align: center;
}

.calendar-today .view-content,
.pane-calendar-panels-panel-pane-1 .view-content,
.pane-calendar-panels-panel-pane-2 .view-content,
.pane-calendar-panels-panel-pane-3 .view-content,
.pane-calendar-panels-panel-pane-4 .view-content,
.ctools-collapsible-container .view-content,
.pane-popular-birthdays-panel-pane-1 .view-content {
  font-size: 90%;
}

.calendar-today .view-content h3,
.pane-calendar-panels-panel-pane-1 .view-content h3,
.pane-calendar-panels-panel-pane-2 .view-content h3,
.pane-calendar-panels-panel-pane-3 .view-content h3,
.pane-calendar-panels-panel-pane-4 .view-content h3,
.ctools-collapsible-container .view-content h3,
.pane-popular-birthdays-panel-pane-1 .view-content h3 {
  text-align: left;
  text-transform: inherit;
  font-size: 1.1em;
  color: #2a2a2a;
  margin-top: 1.5rem;
  margin-bottom: .6rem;
  font-weight: normal;
  letter-spacing: 0;
}

.calendar-today .calendar-buttons,
.pane-calendar-panels-panel-pane-1 .calendar-buttons,
.pane-calendar-panels-panel-pane-2 .calendar-buttons,
.pane-calendar-panels-panel-pane-3 .calendar-buttons,
.pane-calendar-panels-panel-pane-4 .calendar-buttons,
.ctools-collapsible-container .calendar-buttons,
.pane-popular-birthdays-panel-pane-1 .calendar-buttons {
  margin: 1em 0 .3em;
}

.calendar-today .calendar-buttons a,
.calendar-today .calendar-buttons a:visited,
.pane-calendar-panels-panel-pane-1 .calendar-buttons a,
.pane-calendar-panels-panel-pane-1 .calendar-buttons a:visited,
.pane-calendar-panels-panel-pane-2 .calendar-buttons a,
.pane-calendar-panels-panel-pane-2 .calendar-buttons a:visited,
.pane-calendar-panels-panel-pane-3 .calendar-buttons a,
.pane-calendar-panels-panel-pane-3 .calendar-buttons a:visited,
.pane-calendar-panels-panel-pane-4 .calendar-buttons a,
.pane-calendar-panels-panel-pane-4 .calendar-buttons a:visited,
.ctools-collapsible-container .calendar-buttons a,
.ctools-collapsible-container .calendar-buttons a:visited,
.pane-popular-birthdays-panel-pane-1 .calendar-buttons a,
.pane-popular-birthdays-panel-pane-1 .calendar-buttons a:visited {
  display: block;
  width: 70%;
  margin: 1em auto;
  padding: 4px 0;
  color: #993300;
  background: #fff;
  border: 1px solid #993300;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 82%;
  letter-spacing: .7px;
  font-weight: bold;
  text-align: center;
  transition: 250ms all ease-in;
}

.calendar-today .calendar-buttons a:hover,
.pane-calendar-panels-panel-pane-1 .calendar-buttons a:hover,
.pane-calendar-panels-panel-pane-2 .calendar-buttons a:hover,
.pane-calendar-panels-panel-pane-3 .calendar-buttons a:hover,
.pane-calendar-panels-panel-pane-4 .calendar-buttons a:hover,
.ctools-collapsible-container .calendar-buttons a:hover,
.pane-popular-birthdays-panel-pane-1 .calendar-buttons a:hover {
  text-decoration: none;
  color: #fff;
  background: #993300;
}

.calendar-today .pane-calendar-panels-panel-pane-3 .view-content,
.pane-calendar-panels-panel-pane-1 .pane-calendar-panels-panel-pane-3 .view-content,
.pane-calendar-panels-panel-pane-2 .pane-calendar-panels-panel-pane-3 .view-content,
.pane-calendar-panels-panel-pane-3 .pane-calendar-panels-panel-pane-3 .view-content,
.pane-calendar-panels-panel-pane-4 .pane-calendar-panels-panel-pane-3 .view-content,
.ctools-collapsible-container .pane-calendar-panels-panel-pane-3 .view-content,
.pane-popular-birthdays-panel-pane-1 .pane-calendar-panels-panel-pane-3 .view-content {
  margin-top: 2rem;
}

.calendar-today .view-calendar .views-row,
.calendar-today .view-calendar-panels .views-row,
.pane-calendar-panels-panel-pane-1 .view-calendar .views-row,
.pane-calendar-panels-panel-pane-1 .view-calendar-panels .views-row,
.pane-calendar-panels-panel-pane-2 .view-calendar .views-row,
.pane-calendar-panels-panel-pane-2 .view-calendar-panels .views-row,
.pane-calendar-panels-panel-pane-3 .view-calendar .views-row,
.pane-calendar-panels-panel-pane-3 .view-calendar-panels .views-row,
.pane-calendar-panels-panel-pane-4 .view-calendar .views-row,
.pane-calendar-panels-panel-pane-4 .view-calendar-panels .views-row,
.ctools-collapsible-container .view-calendar .views-row,
.ctools-collapsible-container .view-calendar-panels .views-row,
.pane-popular-birthdays-panel-pane-1 .view-calendar .views-row,
.pane-popular-birthdays-panel-pane-1 .view-calendar-panels .views-row {
  margin-bottom: 1em;
}

.calendar-today .view-calendar .today-date,
.calendar-today .view-calendar-panels .today-date,
.pane-calendar-panels-panel-pane-1 .view-calendar .today-date,
.pane-calendar-panels-panel-pane-1 .view-calendar-panels .today-date,
.pane-calendar-panels-panel-pane-2 .view-calendar .today-date,
.pane-calendar-panels-panel-pane-2 .view-calendar-panels .today-date,
.pane-calendar-panels-panel-pane-3 .view-calendar .today-date,
.pane-calendar-panels-panel-pane-3 .view-calendar-panels .today-date,
.pane-calendar-panels-panel-pane-4 .view-calendar .today-date,
.pane-calendar-panels-panel-pane-4 .view-calendar-panels .today-date,
.ctools-collapsible-container .view-calendar .today-date,
.ctools-collapsible-container .view-calendar-panels .today-date,
.pane-popular-birthdays-panel-pane-1 .view-calendar .today-date,
.pane-popular-birthdays-panel-pane-1 .view-calendar-panels .today-date {
  text-align: center;
  margin: 0 0 20px;
}

.calendar-today .views-field-field-address-locality,
.calendar-today .views-field-field-address-name-line,
.pane-calendar-panels-panel-pane-1 .views-field-field-address-locality,
.pane-calendar-panels-panel-pane-1 .views-field-field-address-name-line,
.pane-calendar-panels-panel-pane-2 .views-field-field-address-locality,
.pane-calendar-panels-panel-pane-2 .views-field-field-address-name-line,
.pane-calendar-panels-panel-pane-3 .views-field-field-address-locality,
.pane-calendar-panels-panel-pane-3 .views-field-field-address-name-line,
.pane-calendar-panels-panel-pane-4 .views-field-field-address-locality,
.pane-calendar-panels-panel-pane-4 .views-field-field-address-name-line,
.ctools-collapsible-container .views-field-field-address-locality,
.ctools-collapsible-container .views-field-field-address-name-line,
.pane-popular-birthdays-panel-pane-1 .views-field-field-address-locality,
.pane-popular-birthdays-panel-pane-1 .views-field-field-address-name-line {
  font-size: 90%;
  color: #7a7a7a;
}

.ctools-collapsible-container.ctools-collapsed {
  background: #993300;
  transition: 500ms ease-out;
}

.ctools-collapsible-container.ctools-collapsed h2 {
  color: #fff !important;
  font-weight: 500;
  margin: .2rem 0 .6rem;
}

.view-display-id-block_1 {
  width: 100%;
  margin: 0 8px 2em 0;
  border: 1px solid #e5e5e5;
}

.view-display-id-block_1 .date-nav {
  padding-bottom: 0;
}

.view-display-id-block_1 .date-nav-wrapper {
  margin-top: 1em;
  padding-left: 1em;
}

.view-display-id-block_1 .date-nav-wrapper h3 {
  font-size: 1.25em;
}

.view-display-id-block_1 .date-nav-wrapper .date-next,
.view-display-id-block_1 .date-nav-wrapper .date-prev {
  font-size: 2em;
}

.view-display-id-block_1 .date-nav-wrapper .date-next {
  margin-right: 10px;
}

.view-display-id-block_1 .date-nav-wrapper .date-prev {
  left: 0;
  right: inherit;
}

.view-display-id-block_1 .calendar-calendar td {
  text-align: center;
  vertical-align: middle;
  padding: 5px 0;
  border-top: 1px solid #ccc;
}

.view-display-id-block_1 .calendar-calendar td a,
.view-display-id-block_1 .calendar-calendar td a:visited {
  text-decoration: none;
  color: #993300;
}

.view-display-id-block_1 .calendar-calendar td a:hover {
  color: #6a6a6a;
}

.view-display-id-block_1 .calendar-calendar table.mini td.empty {
  border-color: #ccc;
}

.view-display-id-block_1 .calendar-calendar th.days {
  border: inherit;
  border-top: 1px solid #ccc;
  padding: 5px 0;
  background: #fff;
  color: #444;
}

.view-display-id-block_1 .calendar-calendar tr td.today {
  background-color: #993300;
  transition: 250ms ease-in;
}

.view-display-id-block_1 .calendar-calendar tr td.today a,
.view-display-id-block_1 .calendar-calendar tr td.today a:visited,
.view-display-id-block_1 .calendar-calendar tr td.today a:hover {
  color: #fff;
  font-weight: 600;
}

.view-display-id-block_1 .calendar-calendar td.today:hover {
  background: #6a6a6a;
}

.view-calendar h4,
.view-promoted-events h4 {
  margin-bottom: .5rem;
  margin-top: 0;
  font-size: 1.2rem;
}

.view-calendar .views-field-field-image,
.view-promoted-events .views-field-field-image {
  float: left;
  margin: 0 1.5rem 0 0;
}

.view-calendar .views-row,
.view-promoted-events .views-row {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #b5b5b5;
  overflow: hidden;
}

.view-calendar .views-row-first,
.view-promoted-events .views-row-first {
  padding-top: 2rem;
  border-top: 1px solid #b5b5b5;
}

.view-promoted-events .views-row {
  border: 1px solid #b5b5b5;
}

.view-promoted-events .views-row {
  margin-bottom: 1rem;
  padding: 1.3rem;
  border-bottom: 1px solid #b5b5b5;
  overflow: hidden;
}

.view-promoted-events .views-field-nothing {
  font-size: 70%;
  text-transform: uppercase;
  letter-spacing: .5px;
  color: #8a8a8a;
  margin-bottom: 3px;
}

.view-promoted-events .views-field-body {
  font-size: 85%;
  line-height: 18px;
  margin-top: 9px;
}

.view-promoted-events .views-field-field-address-name-line,
.view-promoted-events .views-field-field-event-date {
  font-weight: 600;
}

.view-promoted-events .views-row-first {
  padding-top: 1.3rem;
  border-top: inherit;
  border: 1px solid #b5b5b5;
  padding-bottom: 0;
}

@media (max-width: 768px) {
  .node-type-event .pane-node-field-image {
    float: none;
    margin: 2em auto;
  }
  .node-type-event .pane-node-field-geolocation {
    float: none;
    width: 100%;
    margin: 1em 0;
  }
  .node-type-event .pane-node-field-website {
    float: none;
    width: 100%;
    margin: 0 0 1em;
  }
  .pane-calendar-panels-panel-pane-1,
  .pane-calendar-panels-panel-pane-2,
  .pane-calendar-panels-panel-pane-3,
  .pane-calendar-panels-panel-pane-4,
  .ctools-collapsible-container {
    padding: 0;
    margin-bottom: 1.5em;
    margin-top: 1.5em;
    border: none;
    margin-right: 0;
  }
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

template,
[hidden] {
  display: none;
}

a {
  color: #993300;
  -webkit-text-decoration-skip: objects;
  text-decoration: none;
  transition: 150ms ease-in;
}

:visited {
  color: #cc4400;
}

a:hover,
a:focus {
  text-decoration: underline;
}

a:active {
  color: #c00;
}

a:active:not(.button) {
  background-color: transparent;
}

a:active,
a:hover {
  outline-width: 0;
}

@media print {
  :link,
  :visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
    font-weight: normal;
    font-size: 16px;
    text-decoration: none;
  }
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: '';
  }
}

.views-field-view-node a {
  color: #993300;
  text-transform: uppercase;
  letter-spacing: .3px;
}

h2 a,
h2 a:visited, h3 a,
h3 a:visited {
  color: #993300;
  transition: 150ms ease-in;
}

h2 a:hover, h3 a:hover {
  color: #666;
  text-decoration: none;
}

h1,
.header__site-name {
  font-size: 2rem;
  line-height: 3rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h1.page-title {
  font-size: 3rem;
  margin-top: .5rem;
  line-height: 3.4rem;
  color: #834b12;
  font-weight: 300;
}

h2 {
  font-size: 1.5rem;
  line-height: 3rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.9em;
  font-weight: 500;
  color: #864d24;
}

h3 {
  font-size: 1.25rem;
  line-height: 2.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-weight: 300;
  font-feature-settings: "lato", sans-serif;
  color: #7c7c7c;
  text-transform: uppercase;
  letter-spacing: .75px;
}

.section-summercamps .pane-node-body h2,
.page-calendar .pane-2 h2,
.section-food .pane-node-body h2,
.node-type-landing-page .pane-node-body h2 {
  font-size: 1.5rem;
  font-weight: 300;
  color: #dc5f13;
  font-family: lato;
  line-height: 2.1rem;
}

h4 {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h5 {
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h6 {
  font-size: 0.625rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

@media (max-width: 768px) {
  h1.page-title {
    font-size: 1.7rem;
    line-height: 2.1rem;
  }
  h2 {
    font-size: 1.4rem;
    margin: 5px 0;
  }
  h3 {
    font-size: 1.1rem;
    margin: 5px 0 10px;
    line-height: 1.2rem;
  }
}

abbr[title] {
  border-bottom: 0;
  text-decoration: underline;
  text-decoration: underline dotted;
}

@media print {
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
}

b,
strong {
  font-weight: inherit;
}

strong,
b {
  font-weight: bolder;
}

pre,
code,
kbd,
samp,
var {
  font-family: Menlo, "DejaVu Sans Mono", "Ubuntu Mono", Courier, "Courier New", monospace, sans-serif;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #fd0;
  color: #2a2a2a;
}

small {
  font-size: 0.875rem;
}

sub,
sup {
  font-size: 0.625rem;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

.divider,
hr {
  margin: 1.5rem 0;
  border: 0;
  border-top: 1px solid #f6f6f6;
}

.divider > :first-child, hr > :first-child {
  margin-top: 1.5rem;
}

blockquote {
  margin: 1.5rem 2rem;
}

dl,
menu,
ol,
ul {
  margin: 1.5rem 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

dd {
  margin: 0 0 0 32px;
}

[dir="rtl"] dd {
  margin: 0 32px 0 0;
}

menu,
ol,
ul {
  padding: 0 0 0 32px;
}

[dir="rtl"] menu, [dir="rtl"]
ol, [dir="rtl"]
ul {
  padding: 0 32px 0 0;
}

figure {
  margin: 1.5rem 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

p,
pre {
  margin: 1.5rem 0;
  margin: 0 0 1.5em;
}

img {
  border-style: none;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  box-sizing: border-box;
  max-width: 100%;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

input {
  overflow: visible;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button, [type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.button,
button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -moz-appearance: button;
  -webkit-appearance: button;
}

.button,
button,
[type='button'],
[type='reset'],
[type='submit'] {
  display: inline-block;
  padding: 2px 6px;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #2a2a2a;
}

.button::-moz-focus-inner,
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

.button:-moz-focusring,
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

.button:hover, .button:focus, .button:active,
button:hover,
button:focus,
button:active,
[type='button']:hover,
[type='button']:focus,
[type='button']:active,
[type='reset']:hover,
[type='reset']:focus,
[type='reset']:active,
[type='submit']:hover,
[type='submit']:focus,
[type='submit']:active {
  text-decoration: none;
  color: #2a2a2a;
}

[disabled].button,
button[disabled],
[disabled][type='button'],
[disabled][type='reset'],
[disabled][type='submit'] {
  cursor: default;
  color: #c3c3c3;
}

[disabled].button:hover,
button[disabled]:hover,
[disabled][type='button']:hover,
[disabled][type='reset']:hover,
[disabled][type='submit']:hover, [disabled].button:focus,
button[disabled]:focus,
[disabled][type='button']:focus,
[disabled][type='reset']:focus,
[disabled][type='submit']:focus, [disabled].button:active,
button[disabled]:active,
[disabled][type='button']:active,
[disabled][type='reset']:active,
[disabled][type='submit']:active {
  color: #c3c3c3;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

fieldset {
  padding: 0.525rem 0.9375rem 0.975rem;
  border: 1px solid #f6f6f6;
  margin: 0 2px;
}

legend {
  box-sizing: border-box;
  display: table;
  max-width: 100%;
  white-space: normal;
  color: inherit;
  margin-left: -5px;
  padding: 0 5px;
}

label {
  display: block;
  font-weight: bold;
}

optgroup {
  font-weight: bold;
}

textarea {
  overflow: auto;
}

table {
  margin: 1.5rem 0;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

td,
th {
  padding: 0;
}

.layout-3col {
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 0;
  padding-right: 0;
}

.layout-3col:before {
  content: "";
  display: table;
}

.layout-3col:after {
  content: "";
  display: table;
  clear: both;
}

.layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar, .layout-3col__col-1, .layout-3col__col-2, .layout-3col__col-3, .layout-3col__col-4, .layout-3col__col-x {
  clear: both;
  padding-left: 20px;
  padding-right: 20px;
  float: left;
  width: 100%;
  margin-left: 0%;
  margin-right: -100%;
}

[dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x {
  float: right;
  margin-right: 0%;
  margin-left: -100%;
}

@media (min-width: 777px) {
  .layout-3col {
    margin-left: -12px;
    margin-right: -13px;
    padding-left: 0;
    padding-right: 0;
  }
  .layout-3col:before {
    content: "";
    display: table;
  }
  .layout-3col:after {
    content: "";
    display: table;
    clear: both;
  }
  [dir="rtl"] .layout-3col {
    margin-left: -13px;
    margin-right: -12px;
  }
  .layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    padding-left: 13px;
    padding-right: 12px;
  }
  .layout-3col__first-left-sidebar, .layout-3col__col-1, .layout-3col__col-3, .layout-3col__col-x:nth-child(2n + 1) {
    float: left;
    width: 50%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    padding-left: 13px;
    padding-right: 12px;
  }
  .layout-3col__second-left-sidebar, .layout-3col__col-2, .layout-3col__col-4, .layout-3col__col-x:nth-child(2n) {
    clear: none;
    float: left;
    width: 50%;
    margin-left: 50%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    float: right;
    margin-right: 50%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    padding-left: 13px;
    padding-right: 12px;
  }
}

@media (min-width: 999px) {
  .layout-3col__full {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__full {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__left-content {
    float: left;
    width: 66.66667%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__left-content {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__right-content {
    float: left;
    width: 66.66667%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__right-content {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__left-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    float: left;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  .layout-3col__right-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    float: left;
    margin-left: 0%;
    margin-right: -100%;
  }
  .layout-3col__col-1, .layout-3col__col-x:nth-child(3n+1) {
    clear: both;
    float: left;
    width: 33.33333%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-x:nth-child(3n+1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__col-2, .layout-3col__col-x:nth-child(3n+2) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-x:nth-child(3n+2) {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__col-3, .layout-3col__col-x:nth-child(3n) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(3n) {
    float: right;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  .layout-3col__col-4 {
    display: none;
  }
}

.layout-3col__grid-item-container {
  padding-left: 0;
  padding-right: 0;
}

.layout-3col__grid-item-container:before {
  content: "";
  display: table;
}

.layout-3col__grid-item-container:after {
  content: "";
  display: table;
  clear: both;
}

.layout-center {
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  max-width: 1250px;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

@media (min-width: 777px) {
  .layout-center {
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-center {
    padding-left: 13px;
    padding-right: 12px;
  }
}

.layout-center--shared-grid,
.layout-center.layout-3col {
  padding-left: 0;
  padding-right: 0;
}

body.page-panels .layout-center {
  width: 100%;
  max-width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

body.page-panels .layout-swap {
  padding-top: 0;
}

.panel-2col-stacked .center-wrapper {
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;
  padding-top: 1em;
}

.panel-2col-stacked .panel-col-first {
  margin-right: -375px;
  float: left;
  width: 100%;
}

.panel-2col-stacked .panel-col-first .inside {
  padding: 0 20px;
  margin-right: 375px;
}

.panel-2col-stacked .panel-col-last {
  width: 375px;
  float: left;
}

.panel-2col-stacked .panel-col-last .inside {
  padding: 0 0 0 20px;
}

.panel-2col-stacked .page-front-page .center-wrapper {
  padding-top: 3em;
}

@media (max-width: 768px) {
  .panel-2col-stacked .panel-col-first {
    width: 100%;
    float: none;
    margin-right: 0;
  }
  .panel-2col-stacked .panel-col-first .inside {
    margin: 0;
  }
  .panel-2col-stacked .panel-col-last {
    width: 100%;
    float: none;
  }
  .panel-2col-stacked .panel-col-last .inside {
    padding: 0 20px;
  }
}

.layout-swap {
  position: relative;
}

@media (min-width: 555px) {
  .layout-swap {
    padding-top: 48px;
  }
}

@media (min-width: 555px) {
  .layout-swap__top {
    position: absolute;
    top: 0;
    height: 48px;
    width: 100%;
  }
}

.box {
  margin-bottom: 1.5rem;
  border: 5px solid #f6f6f6;
  padding: 1em;
}

.box__title {
  margin: 0;
}

.box .layout-3col__full {
  padding: 0;
}

.box:focus, .box:hover, .box.is-focus, .box--is-focus {
  border-color: #2a2a2a;
}

.box--highlight {
  border-color: #993300;
}

.view-front-page-articles-main .views-row {
  margin-bottom: 3.2em;
  overflow: hidden;
  text-align: left;
}

.view-front-page-articles-main h2 {
  font-size: 2rem;
  line-height: 2.5rem;
}

.view-front-page-articles-main .views-field-field-story-images {
  float: left;
  margin-right: 2em;
}

.view-front-page-articles-main .views-field-created h4 {
  font-size: 1.1rem;
  color: #666;
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: .25px;
}

.pane-front-page-articles-secondary-panel-pane-9,
.pane-front-page-articles-secondary-panel-pane-2,
.pane-front-page-articles-main-panel-pane-2,
.articles-list,
.pane-front-page-articles-main-panel-pane-1,
.pane-front-page-articles-secondary-panel-pane-8,
.pane-front-page-articles-secondary-panel-pane-4 {
  overflow: hidden;
  text-align: center;
}

.pane-front-page-articles-secondary-panel-pane-9 h2.pane__title,
.pane-front-page-articles-secondary-panel-pane-2 h2.pane__title,
.pane-front-page-articles-main-panel-pane-2 h2.pane__title,
.articles-list h2.pane__title,
.pane-front-page-articles-main-panel-pane-1 h2.pane__title,
.pane-front-page-articles-secondary-panel-pane-8 h2.pane__title,
.pane-front-page-articles-secondary-panel-pane-4 h2.pane__title {
  font-weight: 300;
  display: inline-block;
  position: relative;
  color: #2a2a2a;
  margin: 0 auto 1em;
  background: #fff;
  padding: 0 .6em;
}

.pane-front-page-articles-secondary-panel-pane-9 h2.pane__title::before,
.pane-front-page-articles-secondary-panel-pane-9 h2.pane__title::after,
.pane-front-page-articles-secondary-panel-pane-2 h2.pane__title::before,
.pane-front-page-articles-secondary-panel-pane-2 h2.pane__title::after,
.pane-front-page-articles-main-panel-pane-2 h2.pane__title::before,
.pane-front-page-articles-main-panel-pane-2 h2.pane__title::after,
.articles-list h2.pane__title::before,
.articles-list h2.pane__title::after,
.pane-front-page-articles-main-panel-pane-1 h2.pane__title::before,
.pane-front-page-articles-main-panel-pane-1 h2.pane__title::after,
.pane-front-page-articles-secondary-panel-pane-8 h2.pane__title::before,
.pane-front-page-articles-secondary-panel-pane-8 h2.pane__title::after,
.pane-front-page-articles-secondary-panel-pane-4 h2.pane__title::before,
.pane-front-page-articles-secondary-panel-pane-4 h2.pane__title::after {
  background: #9a9a9a;
  content: " ";
  display: block;
  height: 2px;
  position: absolute;
  top: 50%;
  width: 40%;
}

.pane-front-page-articles-secondary-panel-pane-9 h2.pane__title::before,
.pane-front-page-articles-secondary-panel-pane-2 h2.pane__title::before,
.pane-front-page-articles-main-panel-pane-2 h2.pane__title::before,
.articles-list h2.pane__title::before,
.pane-front-page-articles-main-panel-pane-1 h2.pane__title::before,
.pane-front-page-articles-secondary-panel-pane-8 h2.pane__title::before,
.pane-front-page-articles-secondary-panel-pane-4 h2.pane__title::before {
  right: 100%;
}

.pane-front-page-articles-secondary-panel-pane-9 h2.pane__title::after,
.pane-front-page-articles-secondary-panel-pane-2 h2.pane__title::after,
.pane-front-page-articles-main-panel-pane-2 h2.pane__title::after,
.articles-list h2.pane__title::after,
.pane-front-page-articles-main-panel-pane-1 h2.pane__title::after,
.pane-front-page-articles-secondary-panel-pane-8 h2.pane__title::after,
.pane-front-page-articles-secondary-panel-pane-4 h2.pane__title::after {
  left: 100%;
}

.view-school-guides .view-header {
  text-align: center;
}

.view-school-guides .view-header h2 {
  font-weight: 300;
  display: inline-block;
  position: relative;
  color: #2a2a2a;
  margin: 1em auto 0;
  padding: 0 .6em;
}

.view-school-guides .view-header h2::before,
.view-school-guides .view-header h2::after {
  background: #9a9a9a;
  content: " ";
  display: block;
  height: 2px;
  position: absolute;
  top: 50%;
  width: 40%;
}

.view-school-guides .view-header h2::before {
  right: 100%;
}

.view-school-guides .view-header h2::after {
  left: 100%;
}

.comment__section h2.comment__section-title,
.comment__section h2.comment__form-title {
  font-weight: 300;
  display: inline-block;
  position: relative;
  color: #2a2a2a;
  margin: 0 auto 1em;
  background: #fff;
  padding: 0 .6em;
}

.comment__section h2.comment__section-title::before,
.comment__section h2.comment__form-title::before,
.comment__section h2.comment__section-title::after,
.comment__section h2.comment__form-title::after {
  background: #9a9a9a;
  content: " ";
  display: block;
  height: 2px;
  position: absolute;
  top: 50%;
  width: 40%;
}

.comment__section h2.comment__section-title::before,
.comment__section h2.comment__form-title::before {
  right: 100%;
}

.comment__section h2.comment__section-title::after,
.comment__section h2.comment__form-title::after {
  left: 100%;
}

.view-front-page-articles-secondary,
.view-taxonomy-term {
  margin-bottom: 3em;
}

.view-front-page-articles-secondary .view-content,
.view-taxonomy-term .view-content {
  overflow: hidden;
}

.view-front-page-articles-secondary .views-row,
.view-taxonomy-term .views-row {
  width: 45%;
  float: left;
  margin-bottom: 2.25em;
  text-align: left;
}

.view-front-page-articles-secondary .views-row-odd,
.view-taxonomy-term .views-row-odd {
  margin-right: 4em;
}

.view-front-page-articles-secondary .more-link,
.view-taxonomy-term .more-link {
  display: block;
  margin: 0 auto;
  width: 220px;
  text-align: center;
}

.view-front-page-articles-secondary .more-link a,
.view-front-page-articles-secondary .more-link a:link,
.view-front-page-articles-secondary .more-link a:visited,
.view-taxonomy-term .more-link a,
.view-taxonomy-term .more-link a:link,
.view-taxonomy-term .more-link a:visited {
  color: #993300;
  text-transform: uppercase;
  font-size: 1.1em;
  letter-spacing: .7px;
  border: 3px solid #993300;
  font-weight: bold;
  padding: 4px 10px;
}

.view-front-page-articles-secondary .more-link a:hover,
.view-taxonomy-term .more-link a:hover {
  text-decoration: none;
  color: #fff;
  background-color: #993300;
}

.view-front-page-articles-secondary h2,
.view-taxonomy-term h2 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.view-front-page-articles-secondary .views-field-created,
.view-taxonomy-term .views-field-created {
  color: #8a8a8a;
  text-transform: uppercase;
  letter-spacing: .25px;
}

.pane-front-page-articles-secondary-panel-pane-9 .view-front-page-articles-secondary {
  margin-bottom: 0;
}

.more-button .more-link {
  display: block;
  margin: 0 auto 4em;
  width: 300px;
  text-align: center;
}

.more-button .more-link a,
.more-button .more-link a:link,
.more-button .more-link a:visited {
  color: #993300;
  text-transform: uppercase;
  font-size: 1.1em;
  letter-spacing: .7px;
  border: 3px solid #993300;
  font-weight: bold;
  padding: 4px 10px;
}

.more-button .more-link a:hover {
  text-decoration: none;
  color: #fff;
  background-color: #993300;
}

.view-school-guides,
.panel-pane .view-id-summer_camp_views {
  padding: 1em 2em 2em;
  background: #ffdeae;
  margin-bottom: 2em;
}

.view-school-guides label,
.panel-pane .view-id-summer_camp_views label {
  font-size: 1.25rem;
}

.view-school-guides .form-type-bef-link,
.panel-pane .view-id-summer_camp_views .form-type-bef-link {
  display: inline-block;
  width: 130px;
  margin: 1em 1em 0 0;
}

.view-school-guides .views-row,
.panel-pane .view-id-summer_camp_views .views-row {
  width: 100%;
  padding: 2em;
  margin-bottom: 1.5em;
  background: #fff;
}

.view-school-guides .Promoted,
.panel-pane .view-id-summer_camp_views .Promoted {
  border: 3px solid #993300;
}

.view-school-guides .views-field-sticky,
.panel-pane .view-id-summer_camp_views .views-field-sticky {
  font-size: .75rem;
  color: #8a8a8a;
}

.view-school-guides .view-content,
.panel-pane .view-id-summer_camp_views .view-content {
  overflow: hidden;
  margin: 1em 0 0;
}

.view-school-guides h3,
.panel-pane .view-id-summer_camp_views h3 {
  line-height: 1.7em;
  margin-top: 0;
  margin-bottom: .4rem;
  text-transform: inherit;
  font-weight: 600;
}

.section-marin-teen-volunteer-opportunities .views-row {
  margin-bottom: 2em;
  padding-bottom: 2em;
  border-bottom: 1px solid #ddd;
}

.section-marin-teen-volunteer-opportunities h2 {
  font-size: 1.45rem;
  margin-bottom: .2rem;
}

.section-marin-teen-volunteer-opportunities .views-field-field-volunteer-address-locality {
  margin-bottom: 1rem;
}

.section-birthdays .view-school-guides .form-type-bef-link {
  display: inline-block;
  width: 230px;
  margin: 1em 1em 0 0;
}

.section-classes-and-camps .view-school-guides .form-type-bef-link {
  width: 170px;
}

.section-classes-and-camps .view-school-guides .form-type-bef-link a.active {
  color: #fff;
  background: #993300;
  padding: 3px 10px;
}

.panel-pane .view-id-summer_camp_views {
  margin-top: 0;
}

.view-summer-camp-views {
  margin-bottom: 2em;
}

.view-summer-camp-views label {
  font-size: 1.25rem;
}

.view-summer-camp-views .form-type-bef-link {
  display: inline-block;
  width: 130px;
  margin: 1em 1em 0 0;
}

.view-summer-camp-views .views-row {
  width: 100%;
  margin-bottom: 2.5em;
  overflow: hidden;
}

.view-summer-camp-views .views-row .views-field-field-image {
  float: right;
}

.view-summer-camp-views .view-content {
  overflow: hidden;
  margin: 2em 0 0;
}

.view-summer-camp-views h3 {
  line-height: 1.7em;
  margin-top: 0;
  margin-bottom: .4rem;
  text-transform: inherit;
  font-weight: 600;
}

.views-widget select.form-select,
select.ctools-jump-menu-select {
  background: #fff;
  padding: .5em;
  margin: 1em 0;
  border: 2px solid #dc5f13;
}

.flexslider {
  background: #3a3a3a;
  border: 0;
  border-radius: 0;
  margin-bottom: 0;
}

.flexslider .flex-direction-nav a {
  line-height: 40px;
}

.flexslider .flex-direction-nav a:before {
  color: #fff;
}

.flexslider .views-field-field-slide-image {
  position: relative;
}

.flexslider .views-field-field-slide-image::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.36), transparent);
}

.flexslider .slide-content {
  width: 100%;
  max-width: 1250px;
  padding: 0 20px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  z-index: 99;
}

.flexslider .slide-content h2.slide-text {
  font-size: 2.7rem;
  line-height: 3rem;
  font-weight: 300;
  text-transform: uppercase;
  width: 40%;
  color: #fff;
  letter-spacing: .5px;
}

.flexslider .slide-content .slide-link {
  margin-top: 10px;
}

.flexslider .slide-content .slide-link a,
.flexslider .slide-content .slide-link a:link,
.flexslider .slide-content .slide-link a:visited {
  font-size: 1.1em;
  padding: 5px 15px;
  border: 3px solid #fff;
  color: #fff;
  letter-spacing: 1.2px;
  transition: 100ms ease-in;
  text-decoration: none;
}

.flexslider .slide-content .slide-link a:hover {
  background: rgba(0, 0, 0, 0.6);
  text-transform: none !important;
  border-color: #3a3a3a;
}

.flexslider .slide-content h3 {
  color: #fff;
  width: 40%;
  text-transform: none;
  line-height: normal;
}

.node-type-landing-page .field-name-body {
  margin-bottom: 3.2rem;
}

/* Real estate listing styles */
.listing--address {
  font-size: 1.2rem;
}

.listing--address .street-block {
  display: inline-block;
  margin-right: .25rem;
}

.listing--address .locality-block {
  display: inline;
}

.listing--price {
  font-size: 1.2rem;
  font-weight: bold;
}

.listing--info {
  float: left;
  margin: 1.5rem 2rem 0 0;
  padding: 2rem;
  width: 40%;
  background: #eaeaea;
}

.listing--map {
  float: left;
  width: 55%;
  margin-top: 1.5rem;
}

.section-summercamps .field-name-body {
  margin-bottom: 1em;
}

.summercamp-sponsor {
  width: 200px;
  float: right;
  margin: 0 0 1em 2em;
  text-align: center;
}

.summercamp-sponsor img {
  width: 100%;
  height: auto;
}

.summercamp-sponsor .pane__title {
  font-weight: 600;
  font-size: 110%;
  margin-bottom: 7px;
}

.birthday-sponsor {
  width: 250px;
  float: right;
  margin: 0 0 1em 1.3em;
  text-align: center;
}

.birthday-sponsor img {
  width: 100%;
  height: auto;
}

.birthday-sponsor .pane__title {
  font-weight: 600;
  font-size: 110%;
}

.node-type-birthday-listing .field-name-field-image {
  margin-bottom: 1em;
}

.node-type-birthday-listing .pane-node-field-phone,
.node-type-birthday-listing .pane-node-field-website,
.node-type-birthday-listing .pane-node-field-email,
.node-type-birthday-listing .pane-node-field-address {
  margin-top: 1em;
}

.pane-promoted-listings-panel-pane-1,
.pane-promoted-listings-panel-pane-2,
.pane-promoted-listings-panel-pane-3 {
  background: #ffdeae;
  padding-top: .6em;
}

.pane-promoted-listings-panel-pane-1 h2.pane__title,
.pane-promoted-listings-panel-pane-2 h2.pane__title,
.pane-promoted-listings-panel-pane-3 h2.pane__title {
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 300;
  font-size: 1.6em;
}

.pane-promoted-listings-panel-pane-3 {
  margin-bottom: 3em;
}

.view-promoted-listings {
  background: #ffdeae;
  padding: .7em 0 2em;
}

.view-promoted-listings .flexslider {
  background: #ffdeae;
}

.view-promoted-listings .flexslider .slides > li {
  text-align: center;
  margin-right: 20px;
  min-height: 228px;
  background: #fff;
  padding: 10px;
}

.view-promoted-listings .flexslider .slides > li h4 {
  margin-top: .5em;
  margin-bottom: 0;
  line-height: 1.3rem;
}

.view-promoted-listings .flex-viewport {
  margin: 0 2.7em;
}

.camp-selectors {
  overflow: hidden;
  width: 100%;
  background: #ffdeae;
  margin-bottom: 2em;
  text-align: center;
}

.camp-selectors .view-summer-camp-views {
  width: 33%;
  float: left;
}

.camp-selectors .view-summer-camp-views .form-item {
  margin: 0 1.5em;
}

.camp-selectors .view-summer-camp-views .form-item .ctools-jump-menu-select {
  width: 100%;
}

.camp-selectors .form-type-textfield label {
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.camp-selectors .form-type-textfield input.custom-search-box {
  border: 2px solid #dc5f13;
  padding: 5px;
}

.node-type-summer-camp-listing .pane-node-field-geolocation,
.node-type-school-listing .pane-node-field-geolocation {
  width: 49%;
  float: left;
  margin: 2em 0;
}

.node-type-summer-camp-listing .pane-node-field-image,
.node-type-school-listing .pane-node-field-image {
  float: right;
  margin: 0 0 1em 2em;
}

.node-type-summer-camp-listing .field-name-field-summer-camp-activities,
.node-type-summer-camp-listing .field-name-field-summer-camp-age-level,
.node-type-school-listing .field-name-field-summer-camp-activities,
.node-type-school-listing .field-name-field-summer-camp-age-level {
  float: left;
  width: 49%;
  margin-top: 1.5em;
}

.node-type-summer-camp-listing .field-name-field-school-address,
.node-type-school-listing .field-name-field-school-address {
  display: inline-block;
}

.node-type-summer-camp-listing .pane-node-field-summer-camp-location,
.node-type-school-listing .pane-node-field-summer-camp-location {
  width: 47%;
  float: left;
  margin: 2em 1em 2em 0;
  background: #ffd79e;
  min-height: 250px;
  clear: both;
}

.node-type-summer-camp-listing .pane-node-field-summer-camp-location .field-name-field-summer-camp-location,
.node-type-school-listing .pane-node-field-summer-camp-location .field-name-field-summer-camp-location {
  margin: 1.5rem;
}

.node-type-summer-camp-listing .pane-node-field-summer-camp-location .field-name-field-summer-camp-location .field-item,
.node-type-school-listing .pane-node-field-summer-camp-location .field-name-field-summer-camp-location .field-item {
  margin-bottom: .7em;
}

.node-type-summer-camp-listing .pane-node-field-website,
.node-type-school-listing .pane-node-field-website {
  margin: 1em 0 2em;
}

.node-type-summer-camp-listing .pane-node-field-website a,
.node-type-summer-camp-listing .pane-node-field-website a:visited,
.node-type-school-listing .pane-node-field-website a,
.node-type-school-listing .pane-node-field-website a:visited {
  color: #fff;
  background: #993300;
  padding: 6px 18px;
  transition: 250ms ease-in;
}

.node-type-summer-camp-listing .pane-node-field-website a:hover,
.node-type-school-listing .pane-node-field-website a:hover {
  background: #4a4a4a;
  text-decoration: none;
}

/* Summer camp webform */
.webform-client-form label {
  margin-bottom: 7px;
}

.webform-client-form fieldset {
  padding: 0;
  margin: 1.5em 0;
  border: 0;
}

.webform-client-form fieldset legend {
  font-weight: 600;
  margin-left: 0;
  padding: 0;
}

.webform-client-form fieldset .fieldset-description {
  font-size: .875rem;
  line-height: 1.5em;
  color: #7a7a7a;
}

.webform-client-form fieldset .fieldset-wrapper {
  border-top: 1px solid #bababa;
  padding-top: 15px;
  margin-top: 15px;
}

.webform-client-form .form-item .description {
  line-height: 1.5em;
  margin-bottom: 10px;
  color: #7a7a7a;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .view-front-page-articles-main .views-row {
    margin-bottom: 2.5em;
    overflow: hidden;
  }
  .view-front-page-articles-main h2 {
    font-size: 1.4rem;
    line-height: 1.7rem;
    margin: .5rem 0;
  }
  .view-front-page-articles-main .views-field-field-story-images {
    width: 295px;
    margin-right: 1.5em;
  }
  .view-front-page-articles-main .views-field-created h4 {
    font-size: .8rem;
    margin: 5px 0;
  }
  .flexslider .slide-content {
    padding: 0 60px;
  }
  .view-front-page-articles-secondary .views-row-odd {
    margin-right: 2em;
  }
  .view-front-page-articles-secondary h2 {
    font-size: 1rem;
    line-height: 1rem;
    margin-top: .25rem;
    margin-bottom: .25rem;
  }
}

@media (max-width: 768px) {
  .view-front-page-articles-main .views-row {
    margin-bottom: 3.5em;
    overflow: hidden;
  }
  .view-front-page-articles-main h2 {
    font-size: 1.4rem;
    line-height: 1.7rem;
    margin: .5rem 0;
  }
  .view-front-page-articles-main .views-field-field-story-images {
    float: none;
    margin-right: 0;
  }
  .view-front-page-articles-main .views-field-created h4 {
    font-size: .8rem;
    margin: 5px 0;
  }
  .view-front-page-articles-secondary .views-row {
    width: 100%;
    float: none;
    margin-bottom: 2em;
  }
  .view-front-page-articles-secondary .views-row-odd {
    margin-right: 0;
  }
  .view-front-page-articles-secondary h2 {
    font-size: 1rem;
    line-height: 1rem;
    margin-top: .25rem;
    margin-bottom: .25rem;
  }
  .listing--info {
    float: none;
    width: 100%;
    margin: 1.5em 0;
  }
  .listing--map {
    float: none;
    margin: 0 0 1.5em;
    width: 100%;
  }
  .node-type-summer-camp-listing .pane-node-field-geolocation {
    width: 100%;
    float: none;
    margin: 1em 0;
  }
  .node-type-summer-camp-listing .field-name-field-summer-camp-activities,
  .node-type-summer-camp-listing .field-name-field-summer-camp-age-level {
    float: none;
    width: 100%;
    margin-top: 1.5em;
  }
  .node-type-summer-camp-listing .pane-node-field-summer-camp-location {
    width: 100%;
    float: none;
    margin-top: 1em;
    height: auto;
  }
  .node-type-summer-camp-listing .pane-node-field-summer-camp-location .field-name-field-summer-camp-location {
    margin: 0;
    padding: 1.5rem;
  }
  .view-summer-camp-views label {
    font-size: 1rem;
  }
  .camp-selectors {
    overflow: hidden;
    width: 100%;
    background: #ffdeae;
    margin-bottom: 2em;
    text-align: center;
  }
  .camp-selectors .view-summer-camp-views {
    width: 100%;
    float: none;
    margin-bottom: -20px;
  }
  .camp-selectors .view-summer-camp-views label {
    font-size: 1em;
    margin-bottom: 5px;
  }
  .camp-selectors .view-summer-camp-views .form-item {
    margin: 0 1.5em;
  }
  .camp-selectors .view-summer-camp-views .form-item .ctools-jump-menu-select {
    width: 100%;
    margin: 5px 0;
  }
  .camp-selectors .form-type-textfield label {
    font-size: 1rem;
    margin-bottom: 5px;
  }
  .camp-selectors .search-form {
    margin-top: 2em;
  }
  .camp-selectors .search-form .form-item {
    margin: 0 1.5em;
  }
  .camp-selectors .search-form .form-text {
    margin-bottom: 5px;
  }
  .flexslider .slide-content {
    padding: 0 0 0 48px;
  }
  .flexslider .slide-content h2.slide-text {
    font-size: 2rem;
    line-height: 2.2rem;
    font-weight: 300;
    text-transform: uppercase;
    width: 70%;
    color: #fff;
    letter-spacing: .5px;
  }
  .flexslider .slide-content .slide-link {
    margin-top: 20px;
  }
  .flexslider .slide-content h3 {
    color: #fff;
    width: 70%;
    text-transform: none;
    line-height: normal;
  }
}

.block-instagram-block {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 30%;
  background: #fff;
  padding: 1em 2em 2em;
  box-shadow: 0 0 8px #999;
}

/** Promoted events **/
/*
.view-promoted-events {
  margin-bottom: 2rem;
  overflow: hidden;

 .views-row {
    width: 30%;
    float: left;
    margin-right: 1.85rem;
    position: relative;
  }

  .views-field-sticky {
    position: absolute;
    left: 0;
    top: 0;
    padding: .1em .7em;
    color: #fff;
    background: #dc5f13;
    font-size: .7em;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .views-row-last {
    margin-right: 0;
  }

  .views-field-field-event-date,
  .views-field-field-address-name-line {
    font-size: .85em;
  }

  h3 {
    font-weight: 600;
    font-size: 1em;
    line-height: 1.2em;
    text-transform: inherit;
    margin: .3rem 0;
    letter-spacing: normal;
  }
}

@media (max-width: 420px) {
  .view-promoted-events {
    .views-row {
      width: 100%;
      float: none;
      margin-right: 0;
      margin-bottom: 1em;
    }
  }
}*/
/* Event block for home page */
.event-block-widget {
  margin-bottom: 1.5em;
  position: relative;
  z-index: 2;
}

.event-block-widget a {
  text-decoration: none;
  color: #4a4a4a;
}

.event-block-widget a:hover {
  color: #993300;
}

.event-block-widget h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  text-transform: none;
  font-weight: 600;
}

.event-block-widget h3 a {
  color: #993300;
}

.event-block-widget h3 a:hover {
  color: #4a4a4a;
}

.event-block-widget .event-text {
  width: 70%;
}

.event-block-widget .event-block__blurb {
  font-size: .9rem;
}

.event-block-widget .event-block__calendar {
  margin-top: 7px;
  margin-right: 8px;
  float: right;
  width: 25%;
  text-align: center;
  border-radius: 7px;
  box-shadow: 0 1px 5px #999;
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
}

.event-block-widget .event-block__calendar::before, .event-block-widget .event-block__calendar::after {
  content: '';
  position: absolute;
  top: -8px;
  z-index: 2;
  width: 7px;
  height: 20px;
  background-color: #aaa;
  border-radius: 3px;
  box-shadow: 0 2px 2px #222;
}

.event-block-widget .event-block__calendar::before {
  left: 12px;
}

.event-block-widget .event-block__calendar::after {
  right: 12px;
}

.event-block-widget .event-block__calendar .month {
  background-color: #993300;
  color: #fff;
  padding: 5px 0;
  border-radius: 7px 7px 0 0;
}

.event-block-widget .event-block__calendar .day {
  padding: 10px 0;
  font-size: 2rem;
}

/* MailChimp form block styles */
.pane-mailchimp-signup-marin-mommies-list,
.mailchimp-signup {
  background-color: #d1e1ff;
  padding: 1em;
  margin-bottom: 1em;
  margin-right: 8px;
}

.pane-mailchimp-signup-marin-mommies-list h2,
.mailchimp-signup h2 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1em;
  line-height: normal;
  font-family: lato;
  font-weight: 600;
  color: #3a3a3a;
}

.pane-mailchimp-signup-marin-mommies-list label,
.mailchimp-signup label {
  display: none;
}

.pane-mailchimp-signup-marin-mommies-list .mailchimp-newsletter-mergefields,
.pane-mailchimp-signup-marin-mommies-list .mc-field-group,
.pane-mailchimp-signup-marin-mommies-list .form-actions,
.mailchimp-signup .mailchimp-newsletter-mergefields,
.mailchimp-signup .mc-field-group,
.mailchimp-signup .form-actions {
  display: inline-block;
}

.pane-mailchimp-signup-marin-mommies-list #mc_embed_signup input,
.mailchimp-signup #mc_embed_signup input {
  border: none;
}

.pane-mailchimp-signup-marin-mommies-list #mc_embed_signup form,
.mailchimp-signup #mc_embed_signup form {
  padding: 10px 0 0;
}

.pane-mailchimp-signup-marin-mommies-list #mc_embed_signup .mc-field-group,
.mailchimp-signup #mc_embed_signup .mc-field-group {
  width: 75% !important;
}

.pane-mailchimp-signup-marin-mommies-list .form-submit,
.mailchimp-signup .form-submit {
  color: #fff;
  background-color: #993300;
  font-weight: 600;
  border: none;
  border-radius: 7px;
  transition: 250ms;
}

.pane-mailchimp-signup-marin-mommies-list .form-submit:hover,
.mailchimp-signup .form-submit:hover {
  background-color: #663300;
}

.pane-mailchimp-signup-marin-mommies-list .form-item,
.mailchimp-signup .form-item {
  margin: 1rem 0 .5rem;
}

.clearfix:before,
.header:before,
.tabs:before {
  content: '';
  display: table;
}

.clearfix:after,
.header:after,
.tabs:after {
  content: '';
  display: table;
  clear: both;
}

.comment__section,
.comments {
  margin: 1.5rem 0;
}

.comment__title {
  margin: 0;
}

.comment__permalink {
  text-transform: uppercase;
  font-size: 75%;
}

.comment--preview,
.comment-preview {
  background-color: #fffadb;
}

.comment--nested,
.indented {
  margin-left: 32px;
}

[dir="rtl"] .comment--nested,
[dir="rtl"] .indented {
  margin-left: 0;
  margin-right: 32px;
}

.comment__section {
  text-align: center;
}

.comment,
.comment-form {
  margin-bottom: 2em;
  text-align: left;
}

.comment header,
.comment-form header {
  box-shadow: none;
}

.comment ul.links,
.comment-form ul.links {
  font-size: 90%;
}

.comment .submitted,
.comment-form .submitted {
  font-size: 90%;
  color: #6a6a6a;
}

header {
  box-shadow: 0px 1px 3px #9a9a9a;
}

.header {
  width: 100% !important;
  height: 200px;
  margin: 0;
  padding: 0;
  background: url("../images/headerBG.png") no-repeat top center;
  transition: height .25s;
}

.header__logo {
  float: left;
  margin: 0 10px 0 0;
  padding: 22px 0 0 0;
}

[dir="rtl"] .header__logo {
  float: right;
  margin: 0 0 0 10px;
}

.header .header-inner {
  max-width: 1250px;
  padding: 0 20px;
  margin: 0 auto;
  height: 100%;
  position: relative;
}

.header .header-inner .block-custom-search-blocks {
  position: absolute;
  right: 20px;
  top: 30%;
}

.header .header-inner .block-custom-search-blocks .form-type-textfield,
.header .header-inner .block-custom-search-blocks .form-actions {
  display: inline-block;
}

.header .header-inner .block-custom-search-blocks input.form-text {
  width: 250px;
  padding: 3px 10px;
  border: none;
}

.header .header-inner .block-custom-search-blocks input.form-submit {
  background: none;
  border: 3px solid #663300;
  color: #663300;
  font-size: 90%;
  text-transform: uppercase;
  letter-spacing: .75px;
  padding: 0 6px;
  margin-left: 3px;
  line-height: 22px;
  font-weight: bold;
}

.header__logo-image {
  vertical-align: bottom;
}

.header__name-and-slogan {
  float: left;
}

.header__site-name {
  margin: 0;
}

.header__site-link:link, .header__site-link:visited {
  color: #2a2a2a;
  text-decoration: none;
}

.header__site-link:hover, .header__site-link:focus {
  text-decoration: underline;
}

.header__site-slogan {
  margin: 0;
}

.header #block-block-3 {
  display: none;
  transition: 200ms;
}

.header #block-block-5 {
  margin: 0;
  padding: 0;
  position: absolute;
  left: 305px;
  bottom: 47px;
}

.header #block-block-4 {
  position: absolute;
  right: 20px;
  bottom: 4px;
}

.header #block-block-4 a,
.header #block-block-4 a:visited {
  color: #663300;
  transition: 100ms ease-in;
}

.header #block-block-4 a:hover {
  color: #6a6a6a;
}

.header #block-block-4 i {
  margin-left: 10px;
}

.header .block-menu,
.header .block-responsive-menu,
.header .block-superfish {
  position: absolute;
  bottom: 11px;
  transition: 200ms ease-in;
}

.header .block-menu ul.menu,
.header .block-menu ul.horizontal-menu,
.header .block-responsive-menu ul.menu,
.header .block-responsive-menu ul.horizontal-menu,
.header .block-superfish ul.menu,
.header .block-superfish ul.horizontal-menu {
  padding: 0;
}

.header .block-menu ul.menu li.menu__item,
.header .block-menu ul.menu li,
.header .block-menu ul.horizontal-menu li.menu__item,
.header .block-menu ul.horizontal-menu li,
.header .block-responsive-menu ul.menu li.menu__item,
.header .block-responsive-menu ul.menu li,
.header .block-responsive-menu ul.horizontal-menu li.menu__item,
.header .block-responsive-menu ul.horizontal-menu li,
.header .block-superfish ul.menu li.menu__item,
.header .block-superfish ul.menu li,
.header .block-superfish ul.horizontal-menu li.menu__item,
.header .block-superfish ul.horizontal-menu li {
  list-style: none;
  float: left;
  margin-right: 40px;
}

.header .block-menu ul.menu li.menu__item ul,
.header .block-menu ul.menu li ul,
.header .block-menu ul.horizontal-menu li.menu__item ul,
.header .block-menu ul.horizontal-menu li ul,
.header .block-responsive-menu ul.menu li.menu__item ul,
.header .block-responsive-menu ul.menu li ul,
.header .block-responsive-menu ul.horizontal-menu li.menu__item ul,
.header .block-responsive-menu ul.horizontal-menu li ul,
.header .block-superfish ul.menu li.menu__item ul,
.header .block-superfish ul.menu li ul,
.header .block-superfish ul.horizontal-menu li.menu__item ul,
.header .block-superfish ul.horizontal-menu li ul {
  background: #fff;
  border-radius: 0;
  margin-top: -15px;
  padding: .7em 0;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.6);
}

.header .block-menu ul.menu li.menu__item ul li,
.header .block-menu ul.menu li ul li,
.header .block-menu ul.horizontal-menu li.menu__item ul li,
.header .block-menu ul.horizontal-menu li ul li,
.header .block-responsive-menu ul.menu li.menu__item ul li,
.header .block-responsive-menu ul.menu li ul li,
.header .block-responsive-menu ul.horizontal-menu li.menu__item ul li,
.header .block-responsive-menu ul.horizontal-menu li ul li,
.header .block-superfish ul.menu li.menu__item ul li,
.header .block-superfish ul.menu li ul li,
.header .block-superfish ul.horizontal-menu li.menu__item ul li,
.header .block-superfish ul.horizontal-menu li ul li {
  margin: .5em 1em;
}

.header .block-menu ul.menu li.last,
.header .block-menu ul.horizontal-menu li.last,
.header .block-responsive-menu ul.menu li.last,
.header .block-responsive-menu ul.horizontal-menu li.last,
.header .block-superfish ul.menu li.last,
.header .block-superfish ul.horizontal-menu li.last {
  margin-right: 0;
}

.header .block-menu ul.menu a,
.header .block-menu ul.menu a.dropdown-toggle,
.header .block-menu ul.menu a:visited,
.header .block-menu ul.menu .nolink,
.header .block-menu ul.horizontal-menu a,
.header .block-menu ul.horizontal-menu a.dropdown-toggle,
.header .block-menu ul.horizontal-menu a:visited,
.header .block-menu ul.horizontal-menu .nolink,
.header .block-responsive-menu ul.menu a,
.header .block-responsive-menu ul.menu a.dropdown-toggle,
.header .block-responsive-menu ul.menu a:visited,
.header .block-responsive-menu ul.menu .nolink,
.header .block-responsive-menu ul.horizontal-menu a,
.header .block-responsive-menu ul.horizontal-menu a.dropdown-toggle,
.header .block-responsive-menu ul.horizontal-menu a:visited,
.header .block-responsive-menu ul.horizontal-menu .nolink,
.header .block-superfish ul.menu a,
.header .block-superfish ul.menu a.dropdown-toggle,
.header .block-superfish ul.menu a:visited,
.header .block-superfish ul.menu .nolink,
.header .block-superfish ul.horizontal-menu a,
.header .block-superfish ul.horizontal-menu a.dropdown-toggle,
.header .block-superfish ul.horizontal-menu a:visited,
.header .block-superfish ul.horizontal-menu .nolink {
  padding: 0;
  color: #663300;
  text-transform: uppercase;
  letter-spacing: .3px;
  font-size: .93em;
  text-decoration: none;
  transition: 150ms ease-in;
}

.header .block-menu ul.menu .nolink,
.header .block-menu ul.horizontal-menu .nolink,
.header .block-responsive-menu ul.menu .nolink,
.header .block-responsive-menu ul.horizontal-menu .nolink,
.header .block-superfish ul.menu .nolink,
.header .block-superfish ul.horizontal-menu .nolink {
  cursor: pointer;
}

.header .block-menu ul.menu a:hover,
.header .block-menu ul.menu .nolink:hover,
.header .block-menu ul.horizontal-menu a:hover,
.header .block-menu ul.horizontal-menu .nolink:hover,
.header .block-responsive-menu ul.menu a:hover,
.header .block-responsive-menu ul.menu .nolink:hover,
.header .block-responsive-menu ul.horizontal-menu a:hover,
.header .block-responsive-menu ul.horizontal-menu .nolink:hover,
.header .block-superfish ul.menu a:hover,
.header .block-superfish ul.menu .nolink:hover,
.header .block-superfish ul.horizontal-menu a:hover,
.header .block-superfish ul.horizontal-menu .nolink:hover {
  text-decoration: none;
  color: #6a6a6a;
}

.header__secondary-menu {
  position: absolute;
  top: 0px;
  right: 20px;
  z-index: 999;
}

[dir="rtl"] .header__secondary-menu {
  float: left;
}

.header__secondary-menu ul.links {
  margin: 10px 0 0 0;
}

.header__secondary-menu ul.links li {
  padding: 0 0 0 1em;
}

.header__secondary-menu ul.links a,
.header__secondary-menu ul.links a:visited {
  color: #6a6a6a;
  font-size: .75em;
  text-transform: uppercase;
  letter-spacing: .45px;
  text-decoration: none;
  transition: 150ms ease-in;
}

.header__secondary-menu ul.links a:hover {
  color: #2a2a2a;
  text-decoration: none;
}

.header__region {
  clear: both;
}

.header.stickynav-active,
.header.floating-block-active {
  z-index: 999;
  height: 50px;
  background-image: none;
  background-color: #fff;
  box-shadow: 0 1px 6px #666;
}

.header.stickynav-active .header__logo,
.header.stickynav-active .header__secondary-menu,
.header.floating-block-active .header__logo,
.header.floating-block-active .header__secondary-menu {
  display: none;
}

.header.stickynav-active .block-superfish,
.header.floating-block-active .block-superfish {
  top: 18px;
  right: 0;
}

.header.stickynav-active #block-block-3,
.header.floating-block-active #block-block-3 {
  display: inline-block;
  margin-top: 12px;
}

.header.stickynav-active #block-block-3 img,
.header.floating-block-active #block-block-3 img {
  width: 80%;
}

.header.stickynav-active .block-custom-search-blocks,
.header.stickynav-active #block-block-4,
.header.floating-block-active .block-custom-search-blocks,
.header.floating-block-active #block-block-4 {
  display: none;
}

.sf-menu .menu-minipanel-panel .panel-display {
  background: #fff;
}

footer {
  width: 100%;
  background: #ffd79e;
  color: #4a4a4a;
  margin-top: 2em;
}

footer a,
footer a:link {
  color: #fff;
}

footer .footerInner {
  max-width: 1250px;
  padding: 20px;
  margin: 0 auto;
  text-align: center;
  font-size: .9em;
}

footer .footerInner .social {
  margin: 2em 0;
}

footer .footerInner .social a,
footer .footerInner .social a:visited {
  color: #663300;
  text-decoration: none;
  transition: 150ms ease-in;
  margin: 5px;
}

footer .footerInner .social a:hover {
  text-decoration: none;
  color: #fff;
}

footer .footerInner .block-mailchimp-signup .form-type-textfield label {
  display: none;
}

.block .header,
.pane-block .header {
  height: inherit;
  width: auto;
  background: none;
}

@media (max-width: 1024px) {
  .header .block-superfish {
    bottom: 14px;
  }
  .header .block-superfish ul.menu li.menu__item,
  .header .block-superfish ul.menu li {
    list-style: none;
    float: left;
    margin-right: 20px;
    font-size: 94%;
  }
  .header .flexslider .slide-content {
    padding: 0 60px;
  }
}

@media (max-width: 1024px) and (max-width: 768px) {
  __secondary-menu {
    display: none;
  }
  .header {
    width: 100%;
    height: 145px;
  }
  .header .header__secondary-menu {
    display: none;
  }
  .header__logo {
    float: none;
    margin: 0 auto;
    padding: 10px 0 0 0;
  }
  .header .header-inner {
    max-width: none;
  }
  .header img.header__logo-image {
    height: auto;
    width: 100%;
    margin-top: 10px;
  }
  .header .block-custom-search-blocks {
    display: none;
  }
  .header #block-block-4 {
    display: none;
  }
  .header .block-superfish {
    position: inherit;
    float: left;
    margin-top: 12px;
  }
  .header .block-superfish .sf-accordion-toggle {
    font-size: 2.3em;
    position: absolute;
  }
  .header .block-superfish .sf-accordion-toggle a,
  .header .block-superfish .sf-accordion-toggle a:hover,
  .header .block-superfish .sf-accordion-toggle a:visited {
    text-decoration: none;
  }
  .header .block-superfish ul.menu {
    margin: 45px 0;
    background: #fff;
  }
  .header .block-superfish ul.menu li {
    padding: 10px 0;
  }
  .header.floating-block-active #block-block-3 {
    display: block;
    position: absolute;
    left: 64px;
  }
  .header.floating-block-active .block-superfish {
    margin-top: 8px;
  }
  .header.floating-block-active .block-superfish ul.menu {
    margin: 40px 0;
    background: #fff;
    padding: 10px 0 20px 10px;
  }
  .header.floating-block-active .block-superfish ul.menu li {
    padding: 10px 0;
  }
  header {
    box-shadow: inherit;
  }
}

.hidden,
html.js .js-hidden,
html.js .element-hidden,
html.js .js-hide {
  display: none;
}

.highlight-mark,
.new,
.update {
  color: #c00;
  background-color: transparent;
}

.inline-links,
.inline.links {
  padding: 0;
}

.inline-links__item,
.inline.links li {
  display: inline;
  list-style-type: none;
  padding: 0 1em 0 0;
}

[dir="rtl"] .inline-links__item,
[dir="rtl"] .inline.links li,
.inline.links [dir="rtl"] li {
  display: inline-block;
  padding: 0 0 0 1em;
}

.inline-sibling,
.field-label-inline .field-label,
span.field-label {
  display: inline;
  margin-right: 10px;
}

[dir="rtl"] .inline-sibling,
[dir="rtl"] .field-label-inline .field-label,
.field-label-inline [dir="rtl"] .field-label,
[dir="rtl"] span.field-label {
  margin-right: 0;
  margin-left: 10px;
}

.inline-sibling__child,
.inline-sibling *, .field-label-inline .field-label *, span.field-label * {
  display: inline;
}

.inline-sibling__adjacent,
.inline-sibling + *,
.inline-sibling + * > :first-child,
.inline-sibling + * > :first-child > :first-child, .field-label-inline .field-label + *, span.field-label + *, .field-label-inline .field-label + * > :first-child, span.field-label + * > :first-child, .field-label-inline .field-label + * > :first-child > :first-child, span.field-label + * > :first-child > :first-child {
  display: inline;
}

.field-label-inline .field-label,
.field-label-inline .field-items {
  float: none;
}

.messages,
.messages--status {
  margin: 1.5rem 0;
  position: relative;
  padding: 10px 10px 10px 44px;
  border: 1px solid #0072b9;
}

[dir="rtl"] .messages,
[dir="rtl"] .messages--status {
  padding: 10px 44px 10px 10px;
  background-position: 99% 8px;
}

.messages__icon {
  position: absolute;
  top: 50%;
  left: 10px;
  height: 24px;
  width: 24px;
  margin-top: -12px;
  line-height: 1;
}

[dir="rtl"] .messages__icon {
  left: auto;
  right: 0;
}

.messages__icon path {
  fill: #0072b9;
}

.messages__highlight,
.messages--error .error,
.messages.error .error {
  color: #2a2a2a;
}

.messages__list {
  margin: 0;
}

.messages__item {
  list-style-image: none;
}

.messages--ok-color,
.messages,
.messages--status,
.ok,
.revision-current {
  background-color: #f6fcff;
  color: #0072b9;
}

.messages--warning-color,
.messages--warning,
.messages.warning,
.warning {
  background-color: #fffce6;
  color: #2a2a2a;
}

.messages--error-color,
.messages--error,
.messages.error,
.error {
  background-color: #fff0f0;
  color: #c00;
}

.messages--warning,
.messages.warning {
  border-color: #fd0;
}

.messages--warning path, .messages.warning path {
  fill: #fd0;
}

.messages--error,
.messages.error {
  border-color: #c00;
}

.messages--error path, .messages.error path {
  fill: #c00;
}

@media print {
  .print-none,
  .toolbar,
  .action-links,
  .links,
  .book-navigation,
  .forum-topic-navigation,
  .feed-icons {
    display: none;
  }
}

.responsive-video,
.media-youtube-video,
.media-vimeo-preview-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.responsive-video__embed,
.responsive-video iframe,
.media-youtube-video iframe,
.media-vimeo-preview-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-video--4-3 {
  padding-bottom: 75%;
}

.visually-hidden,
.element-invisible,
.element-focusable,
.breadcrumb__title,
.main-navigation .block-menu .block__title,
.main-navigation .block-menu-block .block__title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}

.visually-hidden--off,
.visually-hidden--focusable:active,
.visually-hidden--focusable:focus,
.element-focusable:active,
.element-focusable:focus {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: visible;
}

.watermark {
  font-family: Impact, "Arial Narrow", Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  height: 0;
  overflow: visible;
  background-color: transparent;
  color: white;
  font-size: 75px;
  line-height: 1;
  text-align: center;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
}

@media print {
  .breadcrumb {
    display: none;
  }
}

.breadcrumb__list {
  margin: 0;
  padding: 0;
}

.breadcrumb__item {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.more-link,
.more-help-link {
  text-align: right;
}

[dir="rtl"] .more-link,
[dir="rtl"] .more-help-link {
  text-align: left;
}

.more-link__help-icon,
.more-help-link a {
  padding: 1px 0 1px 20px;
  background-image: url(../sass/navigation/more-link/more-link__help-icon.svg);
  background-position: 0 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .more-link__help-icon,
[dir="rtl"] .more-help-link a,
.more-help-link [dir="rtl"] a {
  padding: 1px 20px 1px 0;
  background-position: 100% 50%;
}

.nav-menu__item,
.menu__item {
  list-style-image: url(../sass/navigation/nav-menu/leaf.svg);
  list-style-type: square;
}

.nav-menu__item.is-expanded, .nav-menu__item--is-expanded,
.is-expanded.menu__item,
.menu__item.is-expanded {
  list-style-image: url(../sass/navigation/nav-menu/expanded.svg);
  list-style-type: circle;
}

.nav-menu__item.is-collapsed, .nav-menu__item--is-collapsed,
.is-collapsed.menu__item,
.menu__item.is-collapsed {
  list-style-image: url(../sass/navigation/nav-menu/collapsed.svg);
  list-style-type: disc;
}

[dir="rtl"] .nav-menu__item.is-collapsed, [dir="rtl"] .nav-menu__item--is-collapsed,
[dir="rtl"] .is-collapsed.menu__item,
[dir="rtl"] .menu__item.is-collapsed {
  list-style-image: url(../sass/navigation/nav-menu/collapsed-rtl.svg);
}

.nav-menu__link.is-active, .nav-menu__link--is-active,
.menu a.active {
  color: #2a2a2a;
}

.navbar,
.main-navigation .links,
.main-navigation .menu {
  margin: 0;
  padding: 0;
  text-align: left;
}

[dir="rtl"] .navbar,
[dir="rtl"] .main-navigation .links,
.main-navigation [dir="rtl"] .links,
[dir="rtl"]
.main-navigation .menu,
.main-navigation [dir="rtl"] .menu {
  text-align: right;
}

.navbar__item,
.navbar li, .main-navigation .links li,
.main-navigation .menu li {
  float: left;
  padding: 0 10px 0 0;
  list-style-type: none;
  list-style-image: none;
}

[dir="rtl"] .navbar__item, [dir="rtl"]
.navbar li, [dir="rtl"] .main-navigation .links li, .main-navigation [dir="rtl"] .links li, [dir="rtl"]
.main-navigation .menu li,
.main-navigation [dir="rtl"] .menu li {
  float: right;
  padding: 0 0 0 10px;
}

.pager {
  clear: both;
  padding: 0;
  text-align: center;
}

@media print {
  .pager {
    display: none;
  }
}

.pager__item,
.pager__current-item,
.pager-current,
.pager-item,
.pager-first,
.pager-previous,
.pager-next,
.pager-last,
.pager-ellipsis {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none;
}

.pager__current-item,
.pager-current {
  font-weight: bold;
}

.skip-link {
  display: block;
  padding: 2px 0 3px;
  text-align: center;
}

.skip-link:link, .skip-link:visited {
  background-color: #909090;
  color: #fff;
}

.skip-link__wrapper {
  margin: 0;
}

@media print {
  .skip-link__wrapper {
    display: none;
  }
}

.tabs {
  margin: 1.125rem 0 0;
  line-height: 1.875rem;
  border-bottom: 1px solid #f6f6f6 \0/ie;
  background-image: linear-gradient(to top, #f6f6f6 1px, transparent 1px);
  padding: 0 2px;
  list-style: none;
  white-space: nowrap;
}

@media print {
  .tabs {
    display: none;
  }
}

.tabs__tab {
  float: left;
  margin: 0 3px;
  border: 1px solid #f6f6f6;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-color: transparent;
  border-bottom: 0 \0/ie;
  overflow: hidden;
  background: white;
}

[dir="rtl"] .tabs__tab {
  float: right;
}

.tabs__tab.is-active {
  border-bottom-color: #fff;
}

.tabs__tab-link {
  padding: 0 1.5rem;
  display: block;
  text-decoration: none;
  transition: color .3s, background .3s, border .3s;
  text-shadow: #fff 0 1px 0;
  color: #2a2a2a;
  background: white;
  letter-spacing: 1px;
}

.tabs__tab-link:focus, .tabs__tab-link:hover {
  background: white;
}

.tabs__tab-link:active, .tabs__tab-link.is-active, .tabs__tab-link--is-active {
  background: #fff;
  text-shadow: none;
}

.tabs--off,
.views-displays .secondary {
  margin: 0;
  border-bottom: 0;
  padding: 0;
  background-image: none;
}

.tabs--secondary {
  margin-top: 1.5rem;
  font-size: 0.875rem;
  background-image: none;
}

.tabs + .tabs--secondary {
  margin-top: 0;
  background-image: linear-gradient(to top, #f6f6f6 1px, transparent 1px);
}

.tabs--secondary .tabs__tab {
  margin: 0.5625rem 0.1875rem;
  border: 0;
  background: transparent;
}

[dir="rtl"] .tabs--secondary .tabs__tab {
  float: right;
}

.tabs--secondary .tabs__tab.is-active {
  border-bottom-color: transparent;
}

.tabs--secondary .tabs__tab-link {
  border: 1px solid #f6f6f6;
  border-radius: 24px;
  color: #909090;
  background: white;
  letter-spacing: normal;
}

.tabs--secondary .tabs__tab-link:focus, .tabs--secondary .tabs__tab-link:hover {
  color: #5d5d5d;
  background: white;
  border-color: #bbbbbb;
}

.tabs--secondary .tabs__tab-link:active, .tabs--secondary .tabs__tab-link.is-active, .tabs--secondary .tabs__tab-link--is-active {
  color: white;
  text-shadow: #5d5d5d 0 1px 0;
  background: #909090;
  border-color: #2a2a2a;
}

.autocomplete,
.form-autocomplete {
  background-image: url(../sass/forms/autocomplete/throbber-inactive.png);
  background-position: 100% center;
  background-repeat: no-repeat;
}

[dir="rtl"] .autocomplete,
[dir="rtl"] .form-autocomplete {
  background-position: 0% center;
}

.autocomplete__list-wrapper,
#autocomplete {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}

.autocomplete__list,
#autocomplete ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

.autocomplete__list-item,
#autocomplete li {
  background: #fff;
  color: #2a2a2a;
  cursor: default;
  white-space: pre;
}

.autocomplete__list-item.is-selected, .autocomplete__list-item--is-selected,
#autocomplete li.is-selected,
#autocomplete .selected {
  background: #0072b9;
  color: #fff;
}

.autocomplete.is-throbbing, .autocomplete--is-throbbing,
.is-throbbing.form-autocomplete,
.form-autocomplete.throbbing {
  background-image: url(../sass/forms/autocomplete/throbber-active.gif);
}

.collapsible-fieldset,
.collapsible {
  position: relative;
}

.collapsible-fieldset__legend,
.collapsible .fieldset-legend {
  display: block;
  padding-left: 15px;
  background-image: url(../sass/forms/collapsible-fieldset/expanded.svg);
  background-position: 4px 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .collapsible-fieldset__legend,
[dir="rtl"] .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .fieldset-legend {
  padding-left: 0;
  padding-right: 15px;
  background-position: right 4px top 50%;
}

.collapsible-fieldset__summary,
.collapsible .fieldset-legend .summary {
  color: #c3c3c3;
  font-size: .9em;
  margin-left: .5em;
}

.collapsible-fieldset.is-collapsed, .collapsible-fieldset--is-collapsed,
.is-collapsed.collapsible,
.collapsible.collapsed {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  height: 1em;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__wrapper, .collapsible-fieldset--is-collapsed__wrapper, .is-collapsed.collapsible .collapsible-fieldset__wrapper,
.collapsible.collapsed .fieldset-wrapper {
  display: none;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, .collapsible-fieldset--is-collapsed__legend, .is-collapsed.collapsible .collapsible-fieldset__legend,
.collapsible.collapsed .fieldset-legend,
.collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible .collapsible-fieldset.is-collapsed .fieldset-legend,
.is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed.svg);
  background-position: 4px 50%;
}

[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, [dir="rtl"] .collapsible-fieldset--is-collapsed__legend, [dir="rtl"] .is-collapsed.collapsible .collapsible-fieldset__legend,
[dir="rtl"] .collapsible.collapsed .fieldset-legend,
.collapsible.collapsed [dir="rtl"] .fieldset-legend,
[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .collapsible-fieldset.is-collapsed .fieldset-legend,
[dir="rtl"] .is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed-rtl.svg);
  background-position: right 4px top 50%;
}

.form-item {
  margin: 1.5rem 0;
}

.form-item__required,
.form-required {
  color: #c00;
}

.form-item__description,
.form-item .description {
  font-size: 0.875rem;
}

.form-item--inline div,
.form-item--inline label, .container-inline .form-item div, .container-inline .form-item label {
  display: inline;
}

.form-item--inline__exception,
.container-inline .fieldset-wrapper {
  display: block;
}

.form-item--tight,
.form-item--radio,
.form-type-radio,
.form-type-checkbox,
.password-parent,
.confirm-parent,
table .form-item {
  margin: 0;
}

.form-item--radio .form-item__label, .form-item--radio__label, .form-type-radio .form-item__label,
.form-type-checkbox .form-item__label,
label.option {
  display: inline;
  font-weight: normal;
}

.form-item--radio .form-item__description, .form-item--radio__description, .form-type-radio .form-item__description,
.form-type-checkbox .form-item__description,
.form-type-radio .description,
.form-type-checkbox .description,
.form-item--radio .form-item .description,
.form-item .form-item--radio .description,
.form-type-radio .form-item .description,
.form-item .form-type-radio .description,
.form-type-checkbox .form-item .description,
.form-item
.form-type-checkbox .description {
  margin-left: 1.4em;
}

.form-item.is-error .form-item__widget, .form-item--is-error__widget,
.form-item.is-error input,
.form-item.is-error textarea,
.form-item.is-error select,
.form-item--is-error input,
.form-item--is-error textarea,
.form-item--is-error select,
.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid #c00;
}

.form-table__sticky-header,
.sticky-header {
  position: fixed;
  visibility: hidden;
  margin-top: 0;
  background-color: #fff;
}

.form-table__sticky-header.is-sticky, .form-table__sticky-header--is-sticky,
.is-sticky.sticky-header {
  visibility: visible;
}

.form-table__header,
.form-table th,
form table th {
  border-bottom: 3px solid #f6f6f6;
  padding-right: 1em;
  text-align: left;
}

[dir="rtl"] .form-table__header,
[dir="rtl"] .form-table th,
.form-table [dir="rtl"] th,
[dir="rtl"] form table th,
form table [dir="rtl"] th {
  text-align: right;
  padding-left: 1em;
  padding-right: 0;
}

.form-table__body,
.form-table tbody,
form table tbody {
  border-top: 1px solid #f6f6f6;
}

.form-table__row,
.form-table tbody tr,
form table tbody tr {
  padding: .1em .6em;
  border-bottom: 1px solid #f6f6f6;
  background-color: white;
}

.form-table__row:nth-child(even),
.form-table tbody tr:nth-child(even),
form table tbody tr:nth-child(even) {
  background-color: #fff;
}

.form-table__row.is-active, .form-table__row--is-active,
.form-table tbody tr.is-active,
form table tbody tr.is-active,
td.active {
  background-color: #eeeeee;
}

.form-table__row.is-disabled, .form-table__row--is-disabled,
.form-table tbody tr.is-disabled,
form table tbody tr.is-disabled,
td.menu-disabled {
  background: #f6f6f6;
}

.form-table__row.is-selected, .form-table__row--is-selected,
.form-table tbody tr.is-selected,
form table tbody tr.is-selected,
tr.selected td {
  background: #fffdf0;
}

.form-table__list,
.form-table ul,
form table ul {
  margin: 0;
}

.form-table__narrow-column,
.form-table th.form-table__narrow-column,
form table th.form-table__narrow-column,
td .checkbox,
th .checkbox {
  width: -moz-min-content;
  width: -webkit-min-content;
  text-align: center;
}

.progress-bar,
.progress {
  font-weight: bold;
}

.progress-bar__bar,
.progress .bar {
  border-radius: 3px;
  margin: 0 .2em;
  border: 1px solid #909090;
  background-color: #f6f6f6;
}

.progress-bar__fill,
.progress .filled {
  height: 1.5em;
  width: 5px;
  background: #0072b9 url(../sass/forms/progress-bar/progress-bar.gif) repeat 0 0;
}

.progress-bar__percentage,
.progress .percentage {
  float: right;
}

[dir="rtl"] .progress-bar__percentage,
[dir="rtl"] .progress .percentage,
.progress [dir="rtl"] .percentage {
  float: left;
}

.progress-bar--inline,
.ajax-progress-bar {
  width: 16em;
  display: inline-block;
}

[dir="rtl"] .progress-bar--inline,
[dir="rtl"] .ajax-progress-bar {
  float: right;
}

.progress-throbber,
.ajax-progress {
  display: inline-block;
}

[dir="rtl"] .progress-throbber,
[dir="rtl"] .ajax-progress {
  float: right;
}

.progress-throbber__widget,
.ajax-progress .throbber {
  background: url(../sass/forms/progress-throbber/progress-throbber.gif) no-repeat 0 -18px transparent;
  float: left;
  height: 15px;
  margin: 2px;
  width: 15px;
}

[dir="rtl"] .progress-throbber__widget,
[dir="rtl"] .ajax-progress .throbber,
.ajax-progress [dir="rtl"] .throbber {
  float: right;
}

.progress-throbber__widget-in-tr,
tr .ajax-progress .throbber {
  margin: 0 2px;
}

.progress-throbber__message,
.ajax-progress .message {
  padding-left: 20px;
}

.resizable-textarea,
.resizable-textarea textarea {
  width: 100%;
  vertical-align: bottom;
}

.resizable-textarea__grippie,
.resizable-textarea .grippie {
  background: url(../sass/forms/resizable-textarea/grippie.png) no-repeat center 2px white;
  border: 1px solid #f6f6f6;
  border-top-width: 0;
  cursor: s-resize;
  height: 9px;
  overflow: hidden;
}

.table-drag__wrapper,
body.drag {
  cursor: move;
}

.table-drag__item,
tr.drag {
  background-color: #fffadb;
}

.table-drag__item-previous,
tr.drag-previous {
  background-color: #fff7c2;
}

.table-drag__handle,
.tabledrag-handle {
  cursor: move;
  float: left;
  height: 1.7em;
  margin-left: -1em;
  overflow: hidden;
  text-decoration: none;
  font-size: 12px;
}

[dir="rtl"] .table-drag__handle,
[dir="rtl"] .tabledrag-handle {
  float: right;
  margin-right: -1em;
  margin-left: 0;
}

.table-drag__handle:focus, .table-drag__handle:hover,
.tabledrag-handle:focus,
.tabledrag-handle:hover {
  text-decoration: none;
}

.table-drag__handle-icon,
.tabledrag-handle .handle {
  box-sizing: content-box;
  background: url(../sass/forms/table-drag/handle-icon.png) no-repeat 6px 9px;
  height: 13px;
  margin: -.4em .5em;
  padding: .42em .5em;
  width: 13px;
}

.table-drag__handle.is-hover .table-drag__handle-icon, .table-drag__handle-icon--is-hover, .is-hover.tabledrag-handle .table-drag__handle-icon,
.tabledrag-handle-hover .handle,
.table-drag__handle.is-hover .tabledrag-handle .handle,
.tabledrag-handle .table-drag__handle.is-hover .handle,
.is-hover.tabledrag-handle .handle {
  background-position: 6px -11px;
}

.table-drag__toggle-weight-wrapper,
.tabledrag-toggle-weight-wrapper {
  text-align: right;
}

[dir="rtl"] .table-drag__toggle-weight-wrapper,
[dir="rtl"] .tabledrag-toggle-weight-wrapper {
  text-align: left;
}

.table-drag__toggle-weight,
.tabledrag-toggle-weight {
  font-size: .9em;
}

.table-drag__indentation,
.indentation {
  float: left;
  height: 1.7em;
  margin: -.4em .2em -.4em -.4em;
  padding: .42em 0 .42em .6em;
  width: 20px;
}

[dir="rtl"] .table-drag__indentation,
[dir="rtl"] .indentation {
  float: right;
  margin: -.4em -.4em -.4em .2em;
  padding: .42em .6em .42em 0;
}

.table-drag__tree-child,
.table-drag__tree-child-last,
.tree-child-last,
.table-drag__tree-child-horizontal,
.tree-child-horizontal,
.tree-child {
  background: url(../sass/forms/table-drag/tree-child.png) no-repeat 11px center;
}

[dir="rtl"] .table-drag__tree-child,
[dir="rtl"] .table-drag__tree-child-last,
[dir="rtl"] .tree-child-last,
[dir="rtl"] .table-drag__tree-child-horizontal,
[dir="rtl"] .tree-child-horizontal,
[dir="rtl"] .tree-child {
  background-position: -65px center;
}

.table-drag__tree-child-last,
.tree-child-last {
  background-image: url(../sass/forms/table-drag/tree-child-last.png);
}

.table-drag__tree-child-horizontal,
.tree-child-horizontal {
  background-position: -11px center;
}
