// Headings
//
// All HTML headings, `<h1>` through `<h6>`, are available. `%h1` through `%h6`
// classes are also available, for when you want to match the font styling of a
// heading but still want your text to be displayed inline.
//
// Markup: headings-h.twig
//
// Style guide: base.headings

// Address variable `h1` font-size and margin within `section` and `article`
// contexts in Firefox 4+, Safari, and Chrome.
h1,
%h1 {
  // Set the font-size and line-height while keeping a proper vertical rhythm.
  @include type-layout(xxl, 2);

  // Set 1 unit of vertical rhythm on the top and bottom margins.
  @include margin-top(1, xxl);
  @include margin-bottom(1, xxl);
}

h1.page-title {
  font-size: 3rem;
  margin-top: .5rem;
  line-height: 3.4rem;
  color: #834b12;
  font-weight: 300;
}

h2,
%h2 {
  @include type-layout(xl, 2.0);
  @include margin-top(1, xl);
  @include margin-bottom(1, l);
  font-size: 1.9em;
  font-weight: 500;
  color: #864d24;
}

h3,
%h3 {
  @include type-layout(l, 1.5);
  @include margin-top(1, l);
  @include margin-bottom(1, l);
  font-weight: 300;
  font-feature-settings: "lato", sans-serif;
  color: #7c7c7c;
  text-transform: uppercase;
  letter-spacing: .75px;
}

.section-summercamps .pane-node-body,
.page-calendar .pane-2,
.section-food .pane-node-body,
.node-type-landing-page .pane-node-body {

  h2 {
    font-size: 1.5rem;
    font-weight: 300;
    color: #dc5f13;
    font-family: lato;
    line-height: 2.1rem;
  }

}

h4,
%h4 {
  @include type-layout(m, 1);
  @include margin-top(1, m);
  @include margin-bottom(1, m);
}

h5,
%h5 {
  @include type-layout(s, 1);
  @include margin-top(1, s);
  @include margin-bottom(1, s);
}

h6,
%h6 {
  @include type-layout(xs, 1);
  @include margin-top(1, xs);
  @include margin-bottom(1, xs);
}

@media (max-width: 768px) {

  h1.page-title {
    font-size: 1.7rem;
    line-height: 2.1rem;
  }

  h2,
  %h2 {
    font-size: 1.4rem;
    margin: 5px 0;
  }

  h3,
  %h3 {
    font-size: 1.1rem;
    margin: 5px 0 10px;
    line-height: 1.2rem
  }

}
